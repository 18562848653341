import { createAction } from 'redux-actions';
import { AwsCognitoInteractions } from './types';

export const appStart = createAction(
  `${AwsCognitoInteractions.APP_START}_REQ`
);
export const cognitoSignup = createAction(
  `${AwsCognitoInteractions.SIGNUP}_REQ`
);
export const cognitoLogin = createAction(
  `${AwsCognitoInteractions.LOGIN}_REQ`
);
export const cognitoPasswordReset = createAction(
    `${AwsCognitoInteractions.PASSWORD_RESET}_REQ`
);
export const cognitoPasswordChange = createAction(
    `${AwsCognitoInteractions.PASSWORD_CHANGE}_REQ`
);
export const cognitoLogout = createAction(
  `${AwsCognitoInteractions.LOGOUT}_REQ`
);
