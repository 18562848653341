import React from 'react';
import {compose, setPropTypes, withState} from "recompose";
import PropTypes from "prop-types";
import RegistrationPage from './RegistrationPage';
import RegistrationResultPage from './RegistrationResultPage';


const Page = compose(
    setPropTypes({
        keyInfo: PropTypes.object,
    }),
    withState('isRegistered','setIsRegistered',undefined),
)(props => (
    <div className="py-3 container">
        {!props.isRegistered && <RegistrationPage {...{...props}} />}
        {props.isRegistered && <RegistrationResultPage {...{...props}} />}
     </div>
));

export default Page ;
