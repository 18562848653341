export const SchemaType = {
  object: 'object',
  array: 'array',
  string: 'string',
  number: 'number',
  integer: 'integer',
  boolean: 'boolean',
  null: 'null',
  any: ['object', 'array', 'string', 'number', 'integer', 'boolean', 'null'],
};

export const InputType = {
  DATE: 'date',
  EMAIL: 'email',
  TEXT: 'text',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  CHECKBOX: 'checkbox',
  OPTION: 'option',
  OPTIONS: 'options',
  SELECT: 'select',
  RADIOSELECT: 'radioselect',
  CHECKBOXSELECT: 'checkboxselect',
  TEL: 'tel',
  PASSWORD: 'password',
  ANY: 'any',
};

export const Regions = [
  'Central Lakes',
  'Southland',
  'Dunedin - South Otago',
  'Waitaki',
  'Wellington',
  'Nelson Marlborough',
  'West Coast',
  'Canterbury',
  'South Canterbury',
  'North Auckland',
  'East Auckland',
  'Northland',
  'Central Auckland',
  'Waikato',
  'Bay of Plenty',
  'Lakes',
  'Tairawhiti',
  'Taranaki',
  'Hawkes Bay',
  'Whanganui',
  'MidCentral',
  'West Auckland',
  'Wairarapa',
  'South Auckland',
  'Hutt',
].sort((x,y)=>x.localeCompare(y));

export const ProgramAreas = [
  'Crisis / acute',
  'Mental health',
  'Addictions - other',
  'Self help/ helpline',
  'Addictions - drug & alcohol',
  'Smoking cessation',
  'Maternal & infant mental health',
].sort((x,y)=>x.localeCompare(y));

export const ProgramTypes = [
  'Crisis / acute',
  'Mental health',
  'Addictions - other',
  'Self help/ helpline',
  'Addictions - drug & alcohol',
  'Smoking cessation',
  'Maternal & infant mental health',
  'Intake / assessment',
  'Residential support',
  'Helpline / self-help - gambling',
  'Community / social support',
  'Opioid substitution treatment',
  'Non-acute specialist mental health community services',
  'Coexisting problems – mental health & addictions',
  'Community based',
  'Peer / advocacy / group / family whanau support',
  'Helpline / self-help – mental health',
  'Training',
  'Acute services',
  'Face to face smoking cessation',
  'Community based support - gambling',
  'Advocacy / group / family whanau support',
  'Community based support - other',
  'Respite',
  'Supported employment',
  'Residential alcohol & other drug treatment',
  'Social detox',
  'Eating disorders',
  'Helpline / self-help – addictions (AOD)',
  'Peer support (AOD)',
  'Forensic',
  'Refugee / migrant',
  'Maternal mental health – clinical teams',
  'Disability support',
  'Community (medical detox)',
  'Helpline / self-help – smoking cessation',
  'Venue exclusion for problem gamblers',
].sort((x,y)=>x.localeCompare(y));

export const ReferralTypes = [
  'Self referral',
  'GP',
  'DHB clinical services',
  'Corrections',
].sort((x,y)=>x.localeCompare(y));

export const AgeGroups = [
  'Older Adult',
  'Adult',
  'Youth',
  'Child',
].sort((x,y)=>x.localeCompare(y));


export const SamplesResourceType = [
  'SERVICE',
  'LOCATION',
].sort((x,y)=>x.localeCompare(y));

export const SamplesFormat = {
  JSON:"JSON",
  XML:"XML",
};

export const SamplesArea = {
  JSON:"JSON",
  XML:"XML",
};

