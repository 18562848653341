import React from 'react';
import {connect} from "react-redux";
import {compose} from "recompose";

const Component = compose(
    connect(
        state => ({
            apiGw: state.apiGw,
            accountDetails: state.cognito.accountDetails,
            isLoading: state.cognito.isLoading,
        }),
        null
    )
)(() => (
    <div>
        <p>To help users navigate the Healthpoint website, services currently have a
            single <strong>branch</strong>.
            Each branch was codesigned with health professionals and consumers. Each branch has some common
            fields
            (e.g. phone number) and others that are unique to the branch (e.g. Dispensing manager for pharmacy).
        </p>
        <p>Some branches have detailed profiles of health professionals, so have content in the <strong
            style={{color: "#643DAE"}}>Practitioner</strong> and <strong
            style={{color: "#9D3DAE"}}>PractitionerRole</strong> resources and other branches do not.</p>
        <p><strong>See table below for summary of field documentation for branches in Healthpoint.
            Please note that a resource (e.g. a Pharmacy service) will not necessarily use all of the fields available.
        </strong></p>
        <p>To view samples in JSON or XML for each branch and their associated resources, please go to{' '}
            <strong>
                <a href="#samples">samples</a>
            </strong>.
        </p>
        <table className="doc-data-samples">
            <tbody>
            <tr>
                <td rowSpan="2"><strong>Branch</strong></td>
                <td colSpan="4"><strong>Field Documentation</strong></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#3dae64"><strong>Healthcare service resource</strong></td>
                <td style={{color: "white"}} bgcolor="#3d87ae"><strong>Location resource</strong></td>
                <td style={{color: "white"}} bgcolor="#643dae"><strong>Practitioner resource</strong></td>
                <td style={{color: "white"}} bgcolor="#9d3dae"><strong>PractitionerRole resource</strong></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#585"><strong>GPs / Accident & Urgent Medical
                    Care</strong>
                </td>
                <td><a
                    href="/documentation/GPBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View*</a></td>
                <td><a
                    href="/documentation/GPBranchPractitionerFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesPractitionerRoleFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#479"><strong>Public Hospital Services</strong></td>
                <td><a
                    href="/documentation/PublicBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/PublicandPrivateSpecialtyBranchPractitionerFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesPractitionerRoleFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#479"><strong>Private Hospitals & Specialists</strong>
                </td>
                <td><a
                    href="/documentation/PrivateBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/PublicandPrivateSpecialtyBranchPractitionerFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesPractitionerRoleFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
            </tr>
            <tr>

                <td style={{color:"white"}} bgcolor="#ca6623">
                    <strong>Mental Health &amp; Addictions</strong>
                </td>
                <td style={{textAlign:"center"}}>
                    <a href="/documentation/MHABranchHealthcareServiceFieldDocumentation.pdf" target="_blank">View</a>
                </td>
                <td style={{textAlign:"center"}}>
                    <a href="/documentation/AllBranchesLocationFieldDocumentation.pdf" target="_blank">View*</a>
                </td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#a47"><strong>Midwifery</strong></td>
                <td><a
                    href="/documentation/MidwiferyBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View*</a></td>
                <td><a
                    href="/documentation/MidwiferyBranchPractitionerFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesPractitionerRoleFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#7463a3"><strong>Pharmacy</strong></td>
                <td><a
                    href="/documentation/PharmacyBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View*</a></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#028f89"><strong>Allied Health</strong></td>
                <td><a
                    href="/documentation/AlliedHealthBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View*</a></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#807f70"><strong>Dentistry</strong></td>
                <td><a
                    href="/documentation/DentistryBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View*</a></td>
                <td><a
                    href="/documentation/DentistryBranchPractitionerFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesPractitionerRoleFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#9a8353"><strong>Optometry</strong></td>
                <td><a
                    href="/documentation/OptometryBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View*</a></td>
                <td><a
                    href="/documentation/OptometryBranchPractitionerFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesPractitionerRoleFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#cb9900"><strong>Cancer Support</strong></td>
                <td><a
                    href="/documentation/CancerBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View*</a></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#e66"><strong>Community Health Services</strong></td>
                <td><a
                    href="/documentation/CommunityHealthBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View*</a></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td style={{color: "white"}} bgcolor="#589bb3"><strong>Social Services</strong></td>
                <td><a
                    href="/documentation/SocialBranchHealthcareServiceFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a
                    href="/documentation/AllBranchesLocationFieldDocumentation.pdf"
                    target="_blank" rel="noopener noreferrer">View*</a></td>
                <td></td>
                <td></td>
            </tr>
            </tbody>
        </table>
        <br/>
        <p>* With the exception of when a location is a public or private hospital, all{' '}
            <strong style={{color: "#3D87AE"}}>Location</strong> information from the{' '}
            <strong style={{color: "#3D87AE"}}>Location</strong> resource will be found in the{' '}
            <strong style={{color: "#3DAE64"}}>Healthcare service</strong> resource.</p>
        <p><strong>Additional resources to support the Healthpoint Field documentation:</strong></p>
        <ul>
            <li>The Healthpoint API is documented according to the FHIR<sup>&reg;</sup> R4 standard described here: <a
                href="https://hl7.org/fhir"
                target="_blank"
                rel="noopener noreferrer">https://hl7.org/fhir</a></li>
            <li>Field documentation format descriptions: <a href="http://hl7.org/fhir/formats.html"
                                                            target="_blank"
                                                            rel="noopener noreferrer">http://hl7.org/fhir/formats.html</a>
            </li>
            <li>FHIR<sup>&reg;</sup> Schema: <a href="https://hl7.org/implement/standards/fhir/2015Jan/downloads.html"
                                target="_blank"
                                rel="noopener noreferrer">https://hl7.org/implement/standards/fhir/2015Jan/downloads.html</a>
            </li>
        </ul>
    </div>
));

export default Component;
