import PropTypes from 'prop-types';
import React from 'react';
import { connect } from "react-redux";
import {withFormik} from "formik";
import {compose, setPropTypes} from 'recompose';
import {Container, Row, Col, Form, Button} from 'reactstrap';
import {withRouter} from "react-router-dom";
import * as EmailValidator from "email-validator";
import queryString from 'query-string';
import {InputType, SchemaType} from "../../common/utils/constants";
import FormField from '../../components/terminal/FormField';
import {StyledForm,StyledContainer} from '../../style';
import { cognitoPasswordReset } from "../../common/state/ducks/awsCognito/actions";

const formSchema = {
    $id: 'PasswordResetForm',
    description: 'Log in to Healthpoint FHIR API',
    type: SchemaType.object,
    properties: {
        email: {
            label: 'E-mail',
            description: 'E-mail',
            type: 'email',
            inputType: InputType.EMAIL,
        }
    },
};

const Page = compose(
  connect(() => ({}), { cognitoPasswordReset }),
    withRouter,
    withFormik({
        mapPropsToValues: () => ({email: ""}),
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values, props) => {
            const errors = {} ;
            if (!values.email || !EmailValidator.validate( values.email) ) {
                errors.email = "Please enter a valid e-mail address";
            }
            return errors;
        },
        handleSubmit: (values, formikBag) => {
            return new Promise((resolve,reject) => {
                formikBag.props.cognitoPasswordReset({...values,resolve,reject});
            }).then(result => {
                const {email} = result;
                const params = {email};
                const query = queryString.stringify(params);
                const targetUrl = `/password-change?${query}`;
                console.dir({ params, query,targetUrl });
                formikBag.props.history.push( targetUrl );
            });
        },
        displayName: formSchema.$id,
    }),
    setPropTypes({
        values: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    })
)(({handleSubmit,disabled,...props }) => (
    <StyledContainer>
        <h1>Password Reset</h1>
        <hr/>
        <p>
            Please enter your e-mail.
        </p>
        <Container className="p-0">
            <Row key={1}>
                <Col>
                    <StyledForm>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md="12">
                                    <FormField
                                        {...props}
                                        baseSchema={formSchema}
                                        path={['email']}
                                    />
                                </Col>
                            </Row>
                            <Button disabled={disabled} type={'submit'}>Reset</Button>
                        </Form>
                    </StyledForm>
                </Col>
            </Row>
        </Container>
    </StyledContainer>
));
export default Page;

