import * as R from 'ramda';
import moment from "moment";
import { AwsApiInteractions } from './types';
import { AwsCognitoInteractions } from "../awsCognito/types";

const INIT_STATE = {
  isGwLoaded : false,
  apiKey: undefined,
  usagePlans: [],
  usageByDate: [],
  isActive: false,
  tncAccepted: null,
  samples: [],
  admin: {
    users:[]
  }
};

export default function tab(state = INIT_STATE, action) {
  switch (action.type) {
    case `${AwsCognitoInteractions.LOGOUT}_RES`: {
      return INIT_STATE;
    }
    case `${AwsApiInteractions.API_GW_INIT}_RES`: {
      const {user, usagePlans, apiKey, samples} = action.payload;
      let tncAccepted;
      if (user && user.dUser && user.dUser.TncAccepted) {
        tncAccepted = user.dUser.TncAccepted;
      }
      return {
        ...state,
        user,
        apiKey,
        usagePlans,
        samples,
        usageByDate: [],
        isActive: usagePlans.length > 0,
        tncAccepted
      };
    }
    case `${AwsApiInteractions.ACCEPT_TNCS}_RES`: {
      const {tncAccepted} = action.payload;
      return {
        ...state,
        tncAccepted
      };
    }
    case `${AwsApiInteractions.API_USERS_GET}_RES`: {
      const users = action.payload;
      console.dir({users});
      return {
        ...state,
        admin: {
          ...state.admin,
          users
        }
      };
    }
    default:
      return state;
  }
}
