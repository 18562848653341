import * as R from 'ramda';
import { call, put, takeLatest, take } from 'redux-saga/effects';
import {CognitoUserAttribute} from 'amazon-cognito-identity-js';
import jwt_decode from "jwt-decode";
import { AwsCognitoInteractions } from './types';
import { AwsApiInteractions } from '../awsApiGw/types';
import { sagaEntry } from '../../sagas/utils';
import {
  signUpApi,
} from "../../api/apiAwsCognito";
import getSession  from '../../api/cognito-promises/getSession';
import {signIn,forgotPassword,changePassword}  from '../../api/cognito-promises';
import {getUserPool,getCurrentUserSession,setCurrentUserSession} from '../../api/cognito-promises/config';

// Parse account details from session token
const getAccountDetails = userSession => {
  const idToken = R.path(["idToken","jwtToken"],userSession);
  const decodedIdToken = jwt_decode(idToken);
  return {
    email: decodedIdToken["cognito:username"],
    name: decodedIdToken["name"],
    phone: decodedIdToken["phone_number"],
    organisation: decodedIdToken["custom:organisation"]==="-"?"":decodedIdToken["custom:organisation"],
    position: decodedIdToken["custom:position"]==="-"?"":decodedIdToken["custom:position"],
    message: decodedIdToken["custom:message"]==="-"?"":decodedIdToken["custom:message"],
  };
};

const onAppStart = function*() {
  const user = getUserPool().getCurrentUser() ;
  if (!R.isNil(user)) {
    setCurrentUserSession(user);
    const userSession = yield call(getSession);
    // TODO do I need this ...
    if (userSession) {
      yield put({
        type: `${AwsApiInteractions.API_GW_INIT}_REQ`,
        payload: {
          userSession
        },
      });
      // wait for it to finish
      yield take(`${AwsApiInteractions.API_GW_INIT}_RES`);
    }
    const accountDetails = getAccountDetails(userSession);
    yield put({
      type: `${AwsCognitoInteractions.APP_START}_RES`,
      payload: {
        userSession,
        accountDetails,
        user
      },
    });
    //yield take(`${AwsApiInteractions.APP_START}_RES`);
  } else {
    yield put({
      type: `${AwsCognitoInteractions.APP_START}_RES`,
      payload: {
        userSession: null,
        accountDetails: null,
        user: null,
      },
    });
  }
};

const onCognitoLogin = function*({ payload }) {
  const { resolve, ...params } = payload ;
  // localStorage.clear() ;
  // signIn makes the call to setCurrentUserSession
  const {error,userSession}  = yield call(signIn, params.username, params.password);
  if (error) {
    console.dir({error});
    if (error.code==="PasswordResetRequiredException") {
      yield put({
        type: `${AwsCognitoInteractions.PASSWORD_RESET_REQUIRED}_RES`
      });
    }
    resolve({error});
  } else {
    //yield call(getSession);
    const user = getCurrentUserSession() ;
    const accountDetails = getAccountDetails(userSession);
    console.dir({userSession,user,accountDetails});
    yield put({
      type: `${AwsCognitoInteractions.LOGIN}_RES`,
      payload: {userSession, accountDetails},
    });
    if (userSession) {
      yield put({
        type: `${AwsApiInteractions.API_GW_INIT}_REQ`,
        payload: {userSession},
      });
      // wait for it to finish
      yield take(`${AwsApiInteractions.API_GW_INIT}_RES`);
    }
    resolve({userSession,accountDetails});
  }
};

const onCognitoLogout = function*() {
  const currentUser = getCurrentUserSession();
  if (currentUser != null) {
    currentUser.signOut() ;
    localStorage.clear() ;
  }
  yield put({
    type: `${AwsCognitoInteractions.LOGOUT}_RES`,
  });
};

/* eslint-disable func-names */
const onCognitoSignup = function*({ payload }) {
  const { resolve, ...params } = payload;
  const {
    email,
    name,
    phone,
    position,
    organisation,
    message,
    password,
    confirmPassword,
  } = params;
  if (password !== confirmPassword) {
    throw new Error('Passwords do not match');
  }
  const cognitoPhone = phone => phone.replace("-","");
  const attributeList = [] ;
  attributeList.push(new CognitoUserAttribute({ Name: 'email', Value: email }));
  attributeList.push(new CognitoUserAttribute({ Name: 'name', Value: name }));
  attributeList.push(
    new CognitoUserAttribute({ Name: 'phone_number', Value: cognitoPhone(phone) })
  );
  attributeList.push(
    new CognitoUserAttribute({
      Name: 'custom:organisation',
      Value: organisation,
    })
  );
  attributeList.push(
    new CognitoUserAttribute({
      Name: 'custom:position',
      Value: position?position:"-"
    })
  );
  attributeList.push(
    new CognitoUserAttribute({
      Name: 'custom:message',
      Value: message?message:"-"
    })
  );
  /*attributeList.push(
      new CognitoUserAttribute({
        Name: 'custom:tncs_accepted', Value: "-"
      })
  );*/
  const response = yield call(signUpApi, email, password, attributeList);
  yield put({
    type: `${AwsCognitoInteractions.SIGNUP}_RES`,
    payload: response,
  });
  // TODO - email address is included in here !!!
  if (resolve) {
    resolve(response);
  }
};


const onCognitoPasswordReset = function*({ payload }) {
  const {resolve,reject,...values} = payload;
  const {email} = values;
  const response = yield call(forgotPassword,email);
  const result = {response,email};
  yield put({
    type: `${AwsCognitoInteractions.PASSWORD_RESET}_RES`,
    payload: result,
  });
  resolve(result);
};

const onCognitoPasswordChange = function*({ payload }) {
  const {resolve,reject,...values} = payload;
  const {email, code, password} = values;
  const response = yield call(changePassword,email, code, password);
  const result = {...response,email};
  yield put({
    type: `${AwsCognitoInteractions.PASSWORD_CHANGE}_RES`,
    payload: result
  });
  resolve(result);
};

/* eslint-disable func-names */
export function* watch() {
  yield takeLatest(
    `${AwsCognitoInteractions.APP_START}_REQ`,
    sagaEntry(AwsCognitoInteractions.APP_START, onAppStart,false)
  );
  yield takeLatest(
    `${AwsCognitoInteractions.SIGNUP}_REQ`,
    sagaEntry(AwsCognitoInteractions.SIGNUP, onCognitoSignup )
  );
  yield takeLatest(
    `${AwsCognitoInteractions.LOGIN}_REQ`,
    sagaEntry(AwsCognitoInteractions.SIGNUP, onCognitoLogin)
  );
  yield takeLatest(
    `${AwsCognitoInteractions.LOGOUT}_REQ`,
    sagaEntry(AwsCognitoInteractions.LOGOUT, onCognitoLogout)
  );
  yield takeLatest(
      `${AwsCognitoInteractions.PASSWORD_RESET}_REQ`,
      sagaEntry(AwsCognitoInteractions.PASSWORD_RESET, onCognitoPasswordReset)
  );
  yield takeLatest(
      `${AwsCognitoInteractions.PASSWORD_CHANGE}_REQ`,
      sagaEntry(AwsCognitoInteractions.PASSWORD_CHANGE, onCognitoPasswordChange)
  );
  yield takeLatest(
      `${AwsCognitoInteractions.LOGOUT}_REQ`,
      sagaEntry(AwsCognitoInteractions.LOGOUT, onCognitoLogout)
  );
}
