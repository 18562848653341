import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { compose, setPropTypes, defaultProps, withState } from 'recompose';

const SelectCreatable = compose(
  setPropTypes({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
    multi: PropTypes.bool,
    isClearable: PropTypes.bool,
  }),
  defaultProps({
    isClearable: true,
  }),
  withState('options', 'setOptions', props => props.options)
)(
  ({ createOption, isClearable, ...props }) =>
    createOption ? (
      <ReactSelect.Creatable
        {...{ ...props, isClearable, className: 'check-select', createOption }}
      />
    ) : (
      <ReactSelect {...{ ...props, isClearable, className: 'check-select' }} />
    )
);

export default SelectCreatable;
