import PropTypes from 'prop-types';
import React from 'react';
import * as EmailValidator from "email-validator";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {withFormik} from "formik";
import {compose, setPropTypes, withState} from 'recompose';
import {Container, Row, Col, Form, Button, Alert} from 'reactstrap';
import {NavLink, withRouter} from "react-router-dom";
import queryString from 'query-string';
import {InputType, SchemaType} from "../../common/utils/constants";
import FormField from '../../components/terminal/FormField';
import {cognitoPasswordChange} from "../../common/state/ducks/awsCognito/actions";
import {StyledContainer, StyledForm} from "../../style";
import {passwordSchema} from '../../common/utils';

const formSchema = {
    $id: 'PasswordChangeForm',
    description: 'Change password',
    type: SchemaType.object,
    properties: {
        code: {
            label: 'Confirmation code',
            type: 'text',
            inputType: InputType.TEXT,
        },
        password: {
            label: 'Password',
            type: 'password',
            inputType: InputType.PASSWORD,
        },
        confirmPassword: {
            label: 'Confirm password',
            type: 'password',
            inputType: InputType.PASSWORD,
        },
    },
};

const Page = compose(
    connect(() => ({}), {cognitoPasswordChange}),
    withRouter,
    withState('errorCode','setErrorCode',undefined),
    withFormik({
        mapPropsToValues: props => queryString.parse(props.location.search),
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values, props) => {
            const errors = {};
            if (!values.code ) {
                errors.code = "Verifcation code is required";
            }
            if (!values.password) {
                errors.password = "Password is required";
            } else if (values.password && !passwordSchema.validate(values.password)) {
                errors.password = "Invalid password";
            } else if (values.password && !values.confirmPassword) {
                errors.confirmPassword = "Please confirm password";
            } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "Passwords don't match";
            }
            return errors;
        },
        handleSubmit: (values, formikBag) => new Promise((resolve,reject) => {
            formikBag.props.cognitoPasswordChange({...values,resolve,reject});
        }).then(({error}) => {
            if (error) {
                formikBag.props.setErrorCode(error.code);
            } else {
                formikBag.props.history.push("/dashboard");
            }
        }),
        displayName: formSchema.$id,
    }),
    setPropTypes({
        values: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    })
)(({handleSubmit, disabled, errorCode, ...props}) => {
    // console.dir({errorCode, props});
    if (!props.values.email || !EmailValidator.validate(props.values.email)) {
        return (<Redirect to={"/password-reset"}/>);
    }
    return (
        <StyledContainer>
            <h1>Change Password</h1>
            <hr/>
            <p>
                Please enter the confirmation code sent to your email{' '}
                <a target="_blank" rel="noopener noreferrer" href={`mailto:${props.values.email}`}>{props.values.email}</a>,
                and change your password.
            </p>
            <p>
                Check your spam/junk email folders if you do not receive this email in the next few minutes.
            </p>
            {errorCode==="CodeExpiredException" &&
            <Alert color="warning">
                <h4 className="alert-heading text-center">
                    Verification code is invalid.
                </h4>
                <p className="text-center">
                    Please <NavLink to="/password-reset">reset</NavLink> your password again.
                </p>
            </Alert>
            }
            {errorCode==="CodeMismatchException" &&
            <Alert color="warning">
                <h4 className="alert-heading text-center">
                    Verification code is invalid.
                </h4>
            </Alert>
            }
            <Container className="p-0">
                <Row key={1}>
                    <Col>
                        <StyledForm className="p-3">
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="12">
                                        <FormField
                                            {...props}
                                            baseSchema={formSchema}
                                            path={['code']}
                                        />
                                        <FormField
                                            {...props}
                                            baseSchema={formSchema}
                                            path={['password']}
                                        />
                                        <FormField
                                            {...props}
                                            baseSchema={formSchema}
                                            path={['confirmPassword']}
                                        />
                                    </Col>
                                </Row>
                                <Button disabled={disabled} type={'submit'}>Change</Button>
                            </Form>
                        </StyledForm>
                    </Col>
                </Row>
            </Container>
        </StyledContainer>
    );
});
export default Page;

