
export const AwsApiInteractions = {
  SIGN_IN: 'SIGN_IN',
  ACCEPT_TNCS: 'ACCEPT_TNCS',
  API_GW_INIT: 'API_GW_INIT',
  API_USAGE_GET: 'API_USAGE_GET',
  API_USERS_GET: 'API_USERS_GET',
};



