import React from 'react';
import {toastr} from 'react-redux-toastr';
import {connect} from 'react-redux';
import {compose, setPropTypes} from 'recompose';
import PropTypes from 'prop-types';
import {withFormik} from 'formik';
import * as R from 'ramda';
import {Form, Row, Col, Alert, Button} from 'reactstrap';
import {cognitoSignup} from '../../common/state/ducks/awsCognito/actions';
import FormField from '../../components/terminal/FormField';
import {SchemaType, InputType} from '../../common/utils/constants';
import {passwordSchema} from '../../common/utils';
import * as EmailValidator from "email-validator";

const formSchema = {
    $id: 'RegistrationForm',
    description: 'Search for medical services using Healthpoint FHIR API',
    type: SchemaType.object,
    properties: {
        email: {
            label: 'E-mail',
            description: 'E-mail',
            type: 'email',
            inputType: InputType.EMAIL,
        },
        name: {
            label: 'Name',
            description: '',
            type: 'string',
            inputType: InputType.TEXT,
        },
        phone: {
            label: 'Phone number e.g. +6421555555',
            description: '',
            type: 'tel',
            inputType: InputType.TEL,
        },
        organisation: {
            label: 'Organisation',
            description: 'Organisation',
            type: 'string',
            inputType: InputType.TEXT,
        },
        position: {
            label: 'Position',
            description: 'Position',
            type: 'string',
            inputType: InputType.TEXT,
        },
        message: {
            label: 'What would you like to use the Healthpoint API for?',
            type: 'string',
            inputType: InputType.TEXTAREA,
        },
        password: {
            description: 'Password',
            type: 'text',
            inputType: InputType.PASSWORD,
        },
        confirmPassword: {
            description: 'Confirm Password',
            type: 'text',
            inputType: InputType.PASSWORD,
        },
    },
};

// const phoneRe = /^\+([-. ])?(\(?\d{0,3}\))?([-. ])?\(?\d{0,3}\)?([-. ])?\d{3}([-. ])?\d{4}/;

const RegistrationForm = compose(
    connect(() => ({}), {cognitoSignup}),
    withFormik({
        validateOnChange: true,
        validateOnBlur: true,
        mapPropsToValues: () => ({
            email: "",
            name: "",
            phone: "",
            organisation: "",
            position: null,
            message: null,
            password: "",
            confirmPassword: ""
        }),
        validate: (values, props) => {
            const errors = {};
            if (!values.email || !EmailValidator.validate( values.email) ) {
                errors.email = "Please enter a valid e-mail address";
            }
            values.email = values.email.toLowerCase();
            if (!values.name ) {
                errors.name = "Name is required";
            }
            if (!values.organisation ) {
                errors.organisation = "Organisation is required";
            }
            if (!values.password) {
                errors.password = "Password is required";
            } else if (values.password && !passwordSchema.validate(values.password)) {
                errors.password = "Invalid password";
            } else if (values.password && !values.confirmPassword) {
                errors.confirmPassword = "Please confirm password";
            } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "Passwords don't match";
            }
            if (!values.phone) {
                errors.phone = "Phone number is required";
            } else if (!/^(\+64)?\d+$/.test(values.phone)) {
                errors.phone = "Must contain digits only or start with +64";
            }
            if (!values.phone.startsWith("+64")) {
                values.phone =  `+64${values.phone}`;
            }
            return errors;
        },
        handleSubmit: async (values, formikBag) => {
            console.dir({values, formikBag});
            new Promise((resolve, reject) => {
                formikBag.props.cognitoSignup({resolve, reject, ...values});
            }).then(({isExists}) => {

                if (isExists) {
                    toastr.warning('Warning', false, {
                        timeOut: 10000,
                        showCloseButton: true,
                        component: (
                            <Alert {...{color: 'warning'}}>
                                User already registered.
                            </Alert>
                        ),
                    });
                } else {
                    formikBag.props.setIsRegistered(values.email);
                }
            });
        },
        displayName: 'Registration',
    }),
    setPropTypes({
        values: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    })
)(({handleSubmit, dirty, ...props}) => {
    const noErrors = R.isEmpty(props.errors);
    const disabled = !noErrors  || !dirty ;
    // console.dir({ noErrors, dirty });
    return (
        <Form onSubmit={handleSubmit} autoComplete="on">
            <Row>
                <Col md="4">
                    <FormField
                        {...props}
                        baseSchema={formSchema}
                        path={['email']}
                    />
                </Col>
                <Col md="4">
                    <FormField
                        {...props}
                        baseSchema={formSchema}
                        path={['name']}
                    />
                </Col>
                <Col md="4">
                    <FormField
                        {...props}
                        placeholder={"+64... international format"}
                        baseSchema={formSchema}
                        path={['phone']}
                    />
                </Col>
                <Col md="4">
                    <FormField
                        {...props}
                        baseSchema={formSchema}
                        path={['organisation']}
                    />
                </Col>
                <Col md="4">
                    <FormField
                        {...props}
                        baseSchema={formSchema}
                        path={['position']}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <FormField
                        {...props}
                        baseSchema={formSchema}
                        path={['message']}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <label>
                        Please choose a password of minimum length 8 with uppercase and lowercase letter and a number
                    </label>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <FormField
                        {...props}
                        placeholder={"Choose password"}
                        baseSchema={formSchema}
                        path={['password']}
                    />
                </Col>
                <Col md="6">
                    <FormField
                        {...props}
                        placeholder={"Confirm password"}
                        baseSchema={formSchema}
                        path={['confirmPassword']}
                    />
                </Col>
            </Row>
            <Button disabled={disabled} type={'submit'}>Register</Button>
        </Form>
    )
});
export default RegistrationForm;
