const resources = {
    HealthcareService: {
        name: "Healthcare Service",
        color: "#3DAE64",
    },
    Location: {
        name: "Location",
        color: "#3D87AE",
    },
    PractitionerRole: {
        name: "Practitioner Role",
        color: "#643DAE",
    },
    Practitioner: {
        name: "Practitioner",
        color: "#9D3DAE",
    },
};
export default resources;