import React from 'react';
import * as R from 'ramda';
import {Link, NavLink} from "react-router-dom";
import {Alert, Button} from "reactstrap";
import {compose} from "recompose";
import {connect} from "react-redux";
import {StyledContainer} from '../../style';
import {apiAcceptTncs} from "../../common/state/ducks/awsApiGw/actions";
import whileLoading from "../../components/whileLoading";

const TandC = () => (
    <div className={"p-3"}>
        <h2>Introduction</h2>
        <ol type="A">
            <li>Healthpoint has created and maintains a directory of service information on health service providers
                that
                the Licensee wishes to use for a specific business purpose outlined in the schedule.
            </li>
            <br/>
            <li>Healthpoint is willing to license its directory of service information on health service providers to
                the
                Licensee, and allow the Licensee to access such information from the Licensee Application using the API,
                on
                the terms and conditions contained in this Agreement.
            </li>
        </ol>
        <div className="customListBox">
            <strong>It is agreed :</strong>
            <br/>
            <br/>
            <h2>1. Interpretation</h2>
            <hr/>
            <ol>
                <li>In this Agreement, unless the context otherwise requires:<br/>
                    <br/>
                    <strong>API</strong> means the application programming interface provided by Healthpoint to the
                    Licensee so that the Licensee can use the Directory Information with its own Licensee
                    Application;<br/>
                    <br/>
                    <strong>API Restrictions</strong> means the restrictions on the use of the API and/or the
                    Directory
                    Information (as used with the Licensee Application), as set out in the Schedule;<br/>
                    <br/>
                    <strong>Commencement Date</strong> means the date on which this Agreement is signed by both
                    parties;<br/>
                    <br/>
                    <strong>Confidential Information</strong> means all information exchanged between the parties to
                    this Agreement, whether in writing, electronically or orally, which is marked confidential or is
                    by
                    its nature clearly confidential or could reasonably be expected to be confidential, but does not
                    include information which is, or becomes, publicly available other than through unauthorised
                    disclosure by either party in breach of clause 5;<br/>
                    <br/>
                    <strong>Directory Information</strong> means Healthpoint's national directory of service
                    information
                    on health service providers, including data as described in the Schedule, in XML or JSON format;
                    and<br/>
                    <br/>
                    <strong>Healthpoint Trade Marks</strong> means Healthpoint's trade marks as set out in the
                    Schedule;<br/>
                    <br/>
                    <strong>Intellectual Property Rights</strong> means all property in the Licensed Material and
                    rights
                    to patents, trade marks (including the Healthpoint Trade Marks), service marks, trade names,
                    inventions, trade secrets, copyright, moral rights, rights in a design, look and feel, know-how,
                    confidential information and all or any other intellectual property rights whether or not
                    registered
                    relating to the origin, collation, programming, operating, servicing and/or updating of the
                    Licensed
                    Material and any related updates, enhancements or modifications;<br/>
                    <br/>
                    <strong>Licence Fee</strong> means the sum payable (if specified in the Schedule) by the
                    Licensee
                    for the use of the Licensed Material as described in the Schedule;<br/>
                    <br/>
                    <strong>Licensed Materials</strong> means the Directory Information and the API;<br/>
                    <br/>
                    <strong>Licensee Application</strong> means the software and other technology offered to
                    customers
                    by the Licensee, as described more fully in the Schedule;<br/>
                    <br/>
                    <strong>Permitted Purpose</strong> means the purpose for which the Licensed Material is licensed
                    by
                    Healthpoint to the Licensee, as set out in the Schedule;<br/>
                    <br/>
                    <strong>Schedule</strong> means the schedule to this Agreement;<br/>
                    <br/>
                    <strong>Working Day</strong> means a day other than a Saturday, Sunday or a public holiday in
                    Auckland, New Zealand.
                </li>
                <br/>
                <li>In this Agreement:
                    <ol type="a">
                        <li>clause and other headings are for ease of reference only and will not be deemed to form
                            any
                            part of the context or to affect the interpretation of this Agreement;
                        </li>
                        <li>the word <strong>person</strong> includes a corporation;</li>
                        <li>words importing the singular include the plural and vice versa; and</li>
                        <li>all fees and prices set out in this Agreement exclude GST.</li>
                    </ol>
                </li>
            </ol>
            <h2>2. Grant of Licence</h2>
            <hr/>
            <ol>
                <li>Subject to payment of the Licence Fee, Healthpoint grants to the Licensee a non-exclusive,
                    non-transferable, revocable licence during the term of this Agreement to use the Licensed
                    Material strictly for the Permitted Purpose and in a manner that complies with the API
                    Restrictions.
                </li>
                <br/>
                <li>The licence granted under clause 2.1 permits the Licensee to:
                    <ol type="a">
                        <li>electronically reproduce and distribute the Directory Information strictly for the
                            Permitted Purpose; and
                        </li>
                        <li>print and distribute the Directory Information strictly for the Permitted Purpose,
                        </li>
                    </ol>
                    subject always to the API Restrictions and any reasonable guidelines that may from time to
                    time
                    be established by Healthpoint with respect to the Directory Information.
                </li>
                <br/>
                <li>The Licensee will:
                    <ol type="a">
                        <li>not use the Licensed Material for any purpose other than the Permitted Purpose or in
                            a
                            manner that contravenes the API Restrictions;
                        </li>
                        <li>not use the Licensed Material to compete (directly or indirectly) with Healthpoint,
                            by
                            (for example) using the Licensed Material to create the Licensee’s or a third
                            party’s
                            own directory of health service provider information or other directory or service
                            that
                            contains the information in the Licensed Material;
                        </li>
                        <li>not make the Licensed Material available to any third party except in accordance
                            with
                            the Permitted Purpose or otherwise with the prior written consent from Healthpoint.
                            For
                            the avoidance of doubt and without limitation, if the Licensee is a District Health
                            Board, the Licensee will not make the Licensed Material available to any third party
                            whether or not funded by that DHB;
                        </li>
                        <li>not remove or modify any copyright, trade mark (including the Healthpoint Trade
                            Marks)
                            or other proprietary right notice that is in, or embedded in, the Licensed Material;
                        </li>
                        <li>not use the API to connect to any information source, application or platform other
                            than
                            the Licensee Application and Directory Information;
                        </li>
                        <li>not remove, disable, circumvent or attempt to undermine the integrity of any
                            security or
                            technical measures that control access to the API or the Directory Information;
                        </li>
                        <li>take all necessary steps to ensure the Licensed Material is protected at all times
                            from
                            misuse, damage, destruction or any form of unauthorised access, use or copying; and
                        </li>
                        <li>comply with the reasonable directions of Healthpoint in relation to the access and
                            use
                            of the Licensed Material.
                        </li>
                    </ol>
                </li>
                <br/>
                <li>The Licensee acknowledges and agrees that Healthpoint may:
                    <ol type="a">
                        <li>modify the API or release subsequent versions of the API. The Licensee is
                            responsible
                            for ensuring the Licensee Application integrates with any modified or updated
                            version of
                            the API;
                        </li>
                        <li>discontinue the API at any time. No such discontinuance will be a breach of this
                            Agreement or in any way be deemed to be a termination of this Agreement;
                        </li>
                        <li>prior to the Licensee Application (as it operates with the Directory Information)
                            being
                            made available to third parties, request that the Licensee demonstrates to
                            Healthpoint
                            that the Licensee Application operates with the Directory Information in the manner
                            specified, or other contemplated by, Healthpoint. Healthpoint may request the
                            Licensee
                            to make any reasonable changes to the integration between the Licensee Application
                            and
                            the Directory Information before they are made available to third parties;
                        </li>
                        <li>terminate the licence granted under clause 2.1 by giving the Licensee 5 Working
                            Days'
                            prior notice, if the Licensee Application does not operate with the Directory
                            Information in the manner specified, or as contemplated, by Healthpoint;
                        </li>
                        <li>without prejudice to the API Restrictions, limit the number of API calls to the
                            Directory Information (using the API) that the Licensee will be permitted to make
                            during
                            any given period. Healthpoint will determine, at its sole discretion, call limits
                            based
                            on any factors it considers relevant, including how the Licensee Application is used
                            or
                            the anticipated volume of use associated with the Licensee Application. Healthpoint
                            may
                            charge the Licensee for API calls that exceed the call limits determined by
                            Healthpoint;
                            and
                        </li>
                        <li>vary the Licence Fee by giving the Licensee at least 30 days' prior written
                            notice.
                        </li>
                    </ol>
                </li>
                <br/>
                <li>Healthpoint grants the Licensee a non-exclusive, non-transferable licence to use the
                    Healthpoint
                    Trademarks solely for the purpose of identifying Healthpoint as the provider of the Licensed
                    Material.
                </li>
            </ol>
            <h2>3. Parties' Responsibilities</h2>
            <hr/>
            <ol>
                <li>Healthpoint will, subject to payment of the Licence Fee:
                    <ol type="a">
                        <li>make the Licensed Material available to the Licensee on, or as soon as is
                            reasonably
                            practicable after, the Commencement Date; and
                        </li>
                        <li>thereafter enable access to updated Directory Information via an automated
                            process.
                        </li>
                    </ol>
                </li>
                <br/>
                <li>The Licensee will:
                    <ol type="a">
                        <li>pay all invoices for the Licence Fee by the 20th of the month following the
                            month of
                            the invoice except as otherwise set out in the Schedule. If the Licensee fails
                            to
                            pay any invoice when due, Healthpoint may, without liability to the Licensee,
                            suspend access to the API used by the Licensee until all amounts owing are paid
                            in
                            full;
                        </li>
                        <li>acknowledge Healthpoint as the supplier of the Directory Information:
                            <ol type="i">
                                <li>on every occasion, the Directory Information is used; and</li>
                                <li>on each presentation slide or document page where the Directory
                                    Information
                                    is relied on or referred to;
                                </li>
                            </ol>
                        </li>
                        <li>comply with all applicable law in performing its obligations and exercising its
                            rights under this Agreement;
                        </li>
                        <li>ensure that the Directory Information is kept secure and take prompt and proper
                            remedial action against unauthorised access, copying, modification, storage,
                            reproduction, display or distribution of the Directory Information that would
                            breach
                            the terms of this Agreement;
                        </li>
                        <li>promptly notify Healthpoint of any misuse of, or unauthorised access to, the
                            Directory Information and fully co-operate with Healthpoint to remedy the issue.
                        </li>
                    </ol>
                </li>
            </ol>
            <h2>4. Intellectual Property Rights </h2>
            <hr/>
            <ol>
                <li>The Licensee acknowledges that the Intellectual Property Rights belong to
                    Healthpoint or
                    its licensors and the Licensee will not dispute such ownership.
                </li>
                <br/>
                <li>The Licensee may not copy the Licensed Material in whole or in part in any visual or
                    machine-readable form, except to the extent that such copying is necessary for the
                    Permitted Purpose or the Licensee’s own back-up purposes.
                </li>
                <br/>
                <li>The Licensee will:
                    <ol type="a">
                        <li>not reproduce, translate, reverse-engineer, adapt, vary or modify the
                            Licensed
                            Material, except as expressly permitted by this Agreement;
                        </li>
                        <li>not transfer, assign or otherwise deal in or grant a security interest in
                            the
                            Licensed Material or the Licensee's rights under this Agreement.
                        </li>
                    </ol>
                </li>
                <br/>
                <li>The Licensee acknowledges and agrees that Healthpoint may independently create
                    products
                    or services that may be the same or similar to, or competing with, the Licensee
                    Application. Nothing in this Agreement will be construed as restricting or
                    preventing
                    Healthpoint from creating and fully exploiting such products or services.
                </li>
            </ol>
            <h2>5. Confidentiality </h2>
            <hr/>
            <ol>
                <li>Each party (the <strong>Recipient</strong>) will keep confidential all
                    Confidential
                    Information obtained from the other party (<strong>Disclosing Party</strong>)
                    and,
                    except as permitted by clauses 5.2 and 5.3, will not use for itself or its own
                    benefit, or divulge or make available the same to a third party without the
                    written
                    consent of the Disclosing Party.
                </li>
                <br/>
                <li>The Recipient may divulge Confidential Information only to those of its
                    employees,
                    agents and contractors who are directly involved in fulfilling the Recipient’s
                    obligations under this Agreement and will ensure that such employees, agents and
                    contractors are aware of and comply with these obligations as to
                    confidentiality.
                </li>
                <br/>
                <li>The obligations of confidentiality set out in clause 5.1 will not apply to
                    information that:
                    <ol type="a">
                        <li>the Recipient can clearly show was independently available to it from a
                            third party having the right to disclose it;
                        </li>
                        <li>at the time of disclosure is in the public domain, or subsequently
                            enters
                            the public domain, through no fault of the Recipient or any other person
                            to
                            whom it discloses the information; or
                        </li>
                        <li>the Recipient is obliged by law to disclose, provided that it has first
                            advised the Disclosing Party of this obligation, has allowed the
                            Disclosing
                            Party reasonable time to avoid the disclosure having to be made, and has
                            given the Disclosing Party such assistance (at the Disclosing Party's
                            cost)
                            as the Disclosing Party reasonably requests in doing this.
                        </li>
                    </ol>
                </li>
                <br/>
                <li>Each party will on demand and, in any event, on termination of this Agreement,
                    deliver to the other party all Confidential Information and any other document
                    or
                    information supplied by or obtained from the other party relating to this
                    Agreement.
                </li>
                <br/>
                <li>The obligations of the parties under this clause 5 will survive termination of
                    this
                    Agreement.
                </li>
            </ol>
            <h2>6. Warranty</h2>
            <hr/>
            <ol>
                <li>Healthpoint warrants that:
                    <ol type="a">
                        <li>it has full power and authority to license the Licensed Material to
                            the
                            Licensee; and
                        </li>
                        <li>to the best of its knowledge and belief the Licensed Material does
                            not
                            infringe any New Zealand copyright of any third party.
                        </li>
                    </ol>
                </li>
                <br/>
                <li>Except as expressly provided in this Agreement, all representations or
                    warranties (statutory, express or implied), except any which may not
                    lawfully be
                    excluded, are expressly excluded, including without prejudice to the
                    generality
                    of the foregoing, the implied warranties of merchantability and fitness for
                    a
                    particular purpose.
                </li>
                <br/>
                <li>Without limiting clause 6.2, Healthpoint does not warrant the accuracy of,
                    or
                    endorse, the Directory Information or any other information or materials
                    that
                    are made available via the API. If Healthpoint becomes aware that any such
                    information is inaccurate or misleading, Healthpoint will use reasonable
                    endeavours to correct the information.
                </li>
            </ol>
            <h2>7. Limitation of liability</h2>
            <hr/>
            <ol>
                <li>Healthpoint accepts no responsibility or liability for any errors,
                    omissions, offers or details in, or reliance by any person on, the
                    Directory
                    Information.
                </li>
                <br/>
                <li>Notwithstanding anything in this Agreement to the contrary, Healthpoint
                    will
                    not be liable under the law of tort, contract or otherwise for any loss
                    of
                    profits or any indirect or consequential loss or damage, however caused,
                    arising out of or in connection with this Agreement.
                </li>
                <br/>
                <li>Healthpoint's liability to the Licensee arising out of all claims for
                    damages under this Agreement will not exceed in aggregate the total
                    amount
                    of the Licence Fee paid by the Licensee to Healthpoint in the six months
                    prior to the time the liability arises.
                </li>
            </ol>
            <h2>8. Default and termination</h2>
            <hr/>
            <ol>
                <li>Either party may terminate this Agreement immediately by notice in
                    writing to the other party if that other party:
                    <ol type="a">
                        <li>breaches any of its obligations under this Agreement and
                            fails
                            to remedy the breach within 30 days after notice from the
                            party
                            giving notice requiring the breach to be remedied; or
                        </li>
                        <li>becomes bankrupt, goes or is put into liquidation, has a
                            receiver or statutory manager appointed of any of its
                            assets,
                            becomes insolvent, ceases to carry on its business or makes
                            any
                            composition or arrangement with its creditors.
                        </li>
                    </ol>
                </li>
                <br/>
                <li>Either party may terminate this Agreement on giving not less than 30
                    days' written notice to the other party.
                </li>
                <br/>
                <li>Immediately following termination of this Agreement the Licensee
                    will:
                    <ol type="a">
                        <li>cease to use and destroy all copies of the Licensed Material
                            in
                            whatever form possessed by, or under the control of, the
                            Licensee;
                        </li>
                        <li>deactivate the Licensee Application or otherwise remove its
                            integration with the Directory Information;
                        </li>
                        <li>cease to use the Healthpoint Trade Marks; and</li>
                        <li>pay to Healthpoint all of Healthpoint's outstanding unpaid
                            invoices for the Licence Fee. For the avoidance of doubt,
                            Licensee will not be entitled to any refund of the Licence
                            Fee
                            paid in advance for any period after termination.
                        </li>
                    </ol>
                </li>
                <br/>
            </ol>
            <h2>9. Dispute Resolution</h2>
            <hr/>
            <ol>
                <li>If a dispute arises under this Agreement (Dispute) neither party
                    may
                    commence any court or arbitration proceedings relating to the
                    Dispute unless it has complied with the following provisions of
                    this
                    clause 9, except where the party seeks urgent interlocutory
                    relief.
                </li>
                <br/>
                <li>The party claiming a Dispute has arisen must give written notice
                    to
                    the other party specifying the nature of the Dispute.
                </li>
                <br/>
                <li>On receipt of the notice given pursuant to clause 9.1, the
                    parties
                    will use all reasonable endeavours to resolve the Dispute by
                    discussion, consultation and good faith negotiations.
                </li>
                <br/>
                <li>Any Dispute that is not resolved within 15 Working Days of the
                    notice referred to in to clause 9.2 (or within such longer
                    period
                    agreed in writing by the parties) may be submitted by either
                    party,
                    by giving written notice to the other party, to be finally
                    determined by the arbitration of a single arbitrator. The
                    arbitrator
                    will be appointed by the parties or, failing agreement of the
                    parties, within 5 Working Days of the notice requiring
                    arbitration,
                    by the President of the New Zealand Law Society, on application
                    of
                    either party. The arbitration will be conducted as soon as
                    possible
                    and in accordance with the Arbitration Act 1996.
                </li>
                <br/>
                <li>Nothing in this clause 9 will prevent a party from seeking
                    urgent
                    interlocutory relief from a court of competent jurisdiction.
                </li>
            </ol>
            <h2>10. Force Majeure</h2>
            <hr/>
            <ol>
                <li>Neither party will be responsible or liable for any delay or
                    failure in the performance of its obligations under this
                    Agreement if such delay or failure is due to circumstances
                    outside its reasonable control, including acts, omissions,
                    systems, networks, equipment or data of any third party
                    (including, without limitation, the Internet or any
                    telecommunications or utilities network or equipment), acts
                    of
                    God, natural disaster, riot, terrorism, malicious damage or
                    fire
                    (each an <strong>Event</strong> of <strong>Force
                        Majeure</strong>).
                </li>
                <br/>
                <li>The party affected must:
                    <ol type="a">
                        <li>immediately notify the other party and provide full
                            information concerning the Event of Force Majeure,
                            including an estimate of time likely to be required
                            to
                            overcome the event; and
                        </li>
                        <li>continue to perform its obligations under this
                            Agreement
                            as far as reasonably practicable.
                        </li>
                    </ol>
                </li>
            </ol>
            <h2>11. General</h2>
            <hr/>
            <ol>
                <li>Any notice to be given in terms of this Agreement must
                    be
                    made in writing, by email or by fax sent to the
                    registered
                    office or principal place of business of the other party
                    or
                    to such other address as may be notified by either party
                    to
                    the other from time to time. Any communication by email
                    or
                    fax will be deemed to be received when transmitted to
                    the
                    correct email or fax address of the recipient. Any other
                    communication in writing will be deemed to be received
                    when
                    left at the specified address of the recipient or on the
                    third Working Day following the date of posting.
                </li>
                <br/>
                <li>This Agreement will not be deemed to create a
                    partnership,
                    joint venture or agency relationship of any kind between
                    the
                    parties.
                </li>
                <br/>
                <li>No waiver of any breach of this Agreement will be deemed
                    to
                    be a waiver of any other or any subsequent breach. The
                    failure of any party to enforce any provision of this
                    Agreement will not be interpreted as a waiver of the
                    provision.
                </li>
                <br/>
                <li>This Agreement constitutes the entire agreement between
                    the
                    parties and supersedes all previous negotiations,
                    commitments and/or writings, provided that, where a
                    confidentiality agreement has previously been signed by
                    the
                    parties, that confidentiality agreement will continue in
                    full force and effect, except to the extent of any
                    inconsistency with this Agreement.
                </li>
                <br/>
                <li>No alteration of this Agreement will be binding unless
                    it is
                    in writing and executed by both parties.
                </li>
                <br/>
                <li>The Licensee will not assign or transfer this Agreement
                    or
                    any of its rights or obligations hereunder without the
                    prior
                    written consent of Healthpoint.
                </li>
                <br/>
                <li>If any provision of this Agreement is invalid or
                    unenforceable, the remaining provisions of this
                    Agreement
                    will not be affected and will continue in full force.
                </li>
                <br/>
                <li>This Agreement may be executed by the parties in
                    counterparts, each of which will be deemed to be an
                    original
                    and all of which will constitute one and the same
                    agreement.
                </li>
                <br/>
                <li>This Agreement is subject to the laws of New Zealand and
                    the
                    parties submit to the non-exclusive jurisdiction of the
                    New
                    Zealand courts.
                </li>
            </ol>
        </div>
    </div>
 );

const Component = compose(
    connect(
        ({cognito,apiGw}) => ({
            isAuthenticated: cognito.isAuthenticated,
            accountDetails: cognito.accountDetails,
            tncAccepted: apiGw.tncAccepted,
        }),
        { apiAcceptTncs }
    ),
    whileLoading(props => R.isEmpty(props.accountDetails) )
)(({isAuthenticated,apiAcceptTncs,tncAccepted,...props}) => {
    const isTncsAccepted = !!tncAccepted ;
    return (
        <StyledContainer>
            <h1>Healthpoint API Terms of Use</h1>
            <br/>
            <div className="card" style={{overflowY: "scroll", height: 400}}>
                <TandC/>
            </div>
            <br/>
            <p>These terms of use were last updated on 14 March 2019.</p>
            {(!isAuthenticated && !isTncsAccepted) &&
            <Alert color="warning" className="text-center">
                <p><Link to={"/login"}>Sign-in</Link> to accept.</p>
            </Alert>
            }
            {(isAuthenticated && !isTncsAccepted) &&
            <Alert color="warning" className="text-center">
                <Button onClick={async () => {
                    await new Promise((resolve,reject) => {
                        apiAcceptTncs({resolve,reject});
                    });
                    props.history.push("/dashboard");
                }}>Accept</Button>
            </Alert>
            }
            {(isAuthenticated && isTncsAccepted) &&
            <Alert color="success" className="text-center">
                <h4 className="alert-heading text-center">
                    Terms and conditions accepted
                </h4>
                <p>
                    You accepted these on {tncAccepted.toString()}.
                    Go to your{' '} <NavLink to="/dashboard">dashboard</NavLink>.
                </p>
            </Alert>
            }
        </StyledContainer>
    );
});

export default Component;

