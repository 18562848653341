export const AwsCognitoInteractions = {
  APP_START: 'APP_START',
  SIGNUP: 'SIGNUP',
  CONFIRM: 'CONFIRM',
  RESEND_VERIFY_CODE: 'RESEND_VERIFY_CODE',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  PASSWORD_RESET: 'PASSWORD_RESET',
  PASSWORD_RESET_REQUIRED: 'PASSWORD_RESET_REQUIRED',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
};
