import * as R from "ramda";
import React from 'react';
import Chart from 'chart.js';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {compose, lifecycle, setPropTypes, withHandlers} from "recompose";
import PropTypes from "prop-types";
import whileLoading from "../../components/whileLoading";
import environments from "../../common/utils/environments";
const moment = extendMoment(Moment);

const UsageChart = compose(
    setPropTypes({
        usages: PropTypes.array.isRequired,
        startDate:  PropTypes.object.isRequired,
        endDate:    PropTypes.object.isRequired,
    }),
    withHandlers({
      drawChart: props => () => {
          const {usages,startDate,endDate} = props;
          const range = moment.range(startDate, endDate);
          const dates = Array.from(range.by('days'));
          const labels = dates.map(dt => dt.format('YYYY-MM-DD'));
          const datasets = usages.map( x => {
              if (R.isEmpty(x.items)) {
                  return {};
              }
              const usagePlanName = Object.keys(x.items)[0];
              const series  = x.items[usagePlanName];
              console.dir({ usagePlanName,series });
              return {
                  data: series.map( (x,i) => x[0]),
                  borderWidth: 1,
                  ...environments[x.name]
              };
          }).filter(x => !R.isEmpty(x));
          const ctx = document.getElementById("usages=chart");
          new Chart(ctx, {
              type: 'line',
              data: {
                  labels,
                  datasets,
              },
              options: {
                  scales: {
                      yAxes: [
                          {
                              ticks: {
                                  beginAtZero: true
                              }
                          }
                      ]
                  }
              }
          });
      }
    }),
    lifecycle({
        componentDidMount() {
            const {usages,startDate,endDate} = this.props;
            this.props.drawChart(usages,startDate,endDate);
        },
        componentWillReceiveProps(props) {
            const {usages,startDate,endDate} = props;
            props.drawChart(usages,startDate,endDate);
        },
    }),
    whileLoading(props=> R.isNil(props.usages) ),
)(({ usage }) => {
    return (
        <canvas id="usages=chart" />
    );
});
export default UsageChart;
