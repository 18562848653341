import React from 'react';
import {StyledContainer} from "../../style";

const Component = () => (
    <StyledContainer>
        <h1>API Updates</h1>
        <hr/>
        <ul>
	<li>30/03/2021 Healthcare service resource: Cardinality change "branch-code" changed from 1..1 to 1..*</li>
	<li>30/03/2021 Healthcare service resource: Cardinality change "branch" changed from 1..1 to 1..*</li>
	<li>30/03/2021 Healthcare service resource: New element "communication", language/s that the service can consult with a patient in.</li>
	<li>30/03/2021 Healthcare service resource: New extension "dhb", Healthpoint extension is being replaced by “dhb” as defined by <a href="http://build.fhir.org/ig/HL7NZ/nzbase/branches/master/extensions.html" target="_blank">HL7&reg; FHIR&reg; New Zealand Base Implementation Guide</a>.</li>
	<li>30/03/2021 Healthcare service resource: New extension "pho", Healthpoint extension is being replaced by "pho" as defined by <a href="http://build.fhir.org/ig/HL7NZ/nzbase/branches/master/extensions.html" target="_blank">HL7&reg; FHIR&reg; New Zealand Base Implementation Guide</a>.</li>
	<li>30/03/2021 Healthcare service resource: New extension "funded-programme", Extension as defined by <a href="http://build.fhir.org/ig/HL7NZ/nzbase/branches/master/extensions.html" target="_blank">HL7&reg; FHIR&reg; New Zealand Base Implementation Guide</a>. What was “books” (aka if a GP practice enrolling patients) and govt-funded-Dentistry-care will migrate to here.</li>
	<li>30/03/2021 Healthcare service resource: New extension "services-provided-type", Nested within services. This reflects service type options as defined within Healthpoint. Effectively what is "services-name", with the exception of when services created their own custom items.</li>
	<li>30/03/2021 Healthcare service resource: "region" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "dhb-region" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "document-downloads" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "languages-spoken" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "wheelchair-access" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "parking" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "public-transport" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "staff" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "how-do-i-access" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "age-groups" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "booking-url" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "services-provided" enabled across all services.</li>
	<li>30/03/2021 Healthcare service resource: "charges" included on Dentistry and Mental Health &amp; Addiction services.</li>
	<li>30/03/2021 Healthcare service resource: "fees" removed from Dentistry and Mental Health &amp; Addiction services as "charges" more appropriate.</li>
	<li>30/03/2021 Healthcare service resource: "referral-expectations" removed from Midwifery services as "how-do-i-access more appropriate".</li>
	<li>30/03/2021 Healthcare service resource: Deprecated "services-grouping" (was in Pharmacy and Midwifery branches).</li>
	<li>30/03/2021 Healthcare service resource: Deprecated "service-grouping-url" (was in Pharmacy and Midwifery branches).</li>
	<li>30/03/2021 Location resource: New extension "dhb", Healthpoint extension is being replaced by “dhb” as defined by <a href="http://build.fhir.org/ig/HL7NZ/nzbase/branches/master/extensions.html" target="_blank">HL7&reg; FHIR&reg; New Zealand Base Implementation Guide</a>.</li>
	<li>30/03/2021 Practitioner resource: New element "communication", language/s that the practitioner can consult with a patient in.</li>
	<li>30/03/2021 Practitioner resource: Cardinality change "service-type" (nested within service-list extension) changed from 1..1 to 1..*</li>
	<li>30/03/2021 Practitioner resource: "gender" enabled across all "person-types".</li>
	<li>30/03/2021 Practitioner resource: "interests" enabled across all "person-types".</li>
	<li>30/03/2021 Healthcare service resource: Inclusion of healthlink-edi in pharmacy branch.</li>
	<li>26/04/2020 Healthcare service resource: Inclusion of healthlink-edi in pharmacy branch.</li>
	<li>27/03/2020 Healthcare service resource: Inclusion of HPI-F and HPI-O(beginning with Pharmacy and GP practice, urgent care clinics).</li>
	<li>27/03/2020 Healthcare service resource: Inclusion of HPI-F and HPI-O as query parameters in the Healthcare services resource.</li>
	<li>27/03/2020 Healthcare service resource: Inclusion of Prescription Email, for pharmacy services.</li>
	<li>07/03/2020: Healthcare service resource: Inclusion of Allied Health branch in Healthpoint API (More that 2,500 services).</li>
	<li>07/03/2020: Healthcare service resource: Deprecated "National patient support group" branch - this content has been merged into Social, Community of Mental Health and Addictions content, as appropriate.</li>
	<li>09/08/2019 Practitioner resource: New  resourceto include "person-title" (also found within the Service resource).</li>
	<li>08/08/2019 Extension of Practitioner resource to include a service list (i.e. a list of services the practitioner works at). This list includes service-id (which links to service-resource), services-name and service-type.</li>
</ul> 
        <hr/>
    </StyledContainer>
);

export default Component;



