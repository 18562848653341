import PropTypes from 'prop-types';
import React from 'react';
import {connect} from "react-redux";
import {compose, lifecycle, setPropTypes, withProps, withState} from 'recompose';
import {Container, Row, Col, Form, Button, Alert, Tooltip, Table} from 'reactstrap';
import {Link, withRouter} from "react-router-dom";
import {withFormik} from "formik";
import {StyledForm, StyledContainer, TrForEnv} from '../../style';
import {apiGwUsersGet} from "../../common/state/ducks/awsApiGw/actions";
import {InputType, SchemaType} from "../../common/utils/constants";
import FormField from '../../components/terminal/FormField';
import * as R from "ramda";
import whileLoading from "components/whileLoading";

const formSchema = {
    $id: 'UsersForm',
    description: 'Search for users',
    type: SchemaType.object,
    properties: {
        filter: {
            label: 'filter',
            description: 'Filter',
            type: 'text',
            inputType: InputType.TEXT,
        },
    },
};

const Th = compose(
    withState('isOpen', 'setIsOpen', false)
)(({title, isOpen, setIsOpen, doc, children, ...props}) => (
    <th>
        <span>
            <strong>{title}</strong>
            <i id={title} className="p-2 fa fa-info-circle">
                <Tooltip placement="top" isOpen={isOpen} target={title} toggle={() => setIsOpen(!isOpen)}>
                    {children}
                </Tooltip>
            </i>
        </span>
    </th>
));

const ThEnv = compose(
    withState('isOpen', 'setIsOpen', false)
)(({env, isOpen, setIsOpen, children, ...props}) => (
    <th>
        <span>
            <Link id={env.label} to={`/open-api?env=${env.name}`}>{env.label}</Link>
            <Tooltip placement="top" isOpen={isOpen} target={env.label} toggle={() => setIsOpen(!isOpen)}>
                Click to open in Open API.
            </Tooltip>
        </span>
    </th>
));

const UserRow = compose(
    setPropTypes({
        user: PropTypes.object.isRequired,
    }),
    withState('isShowUsage', 'setIsShowUsage'),
)(user => {
    const {userPoolId, region} = window.config;
    return (
        <React.Fragment>
            <TrForEnv>
                <td>
                    {R.path(["cUser", "Username"], user) &&
                    <a target="_blank"
                       rel="noopener noreferrer"
                       href={encodeURI(`https://${region}.console.aws.amazon.com/cognito/users/?region=${region}#/pool/${userPoolId}/users/${user.cUser.Username}`)}>
                        {user.cUser.Username}
                    </a>}
                </td>
                <td>
                    {R.path(["dUser", "ApiKeyId"], user) &&
                    <a target="_blank"
                       rel="noopener noreferrer"
                       href={encodeURI(`https://${region}.console.aws.amazon.com/apigateway/home?region=${region}#/api-keys/${user.dUser.ApiKeyId}`)}>
                        {user.dUser.ApiKeyId}
                    </a>}
                </td>
                <td>
                    {R.path(["dUser", "TncAccepted"], user)}
                </td>
                <td>
                    {R.path(["dUser", "UatDate"], user)}
                </td>
                <td>
                    {R.path(["dUser", "ProdDate"], user)}
                </td>
            </TrForEnv>
        </React.Fragment>
    );
});
const LoadingTable = compose(
    whileLoading(props => props.isLoading),
)(props => (
    <Table>
        <thead>
        <tr>
            <Th title={"Email"}>
                <p>Email (Cognito userame)</p>
            </Th>
            <Th title={"ApiKeyId"}>
                <p>API key ID</p>
            </Th>
            <Th title={"TncDate"}>
                <p>When T&amp;Cs accepted.</p>
            </Th>
            <Th title={"UatDate"}>
                <p>UAT date</p>
            </Th>
            <Th title={"ProdDate"}>
                <p>Production date</p>
            </Th>
        </tr>
        </thead>
        <tbody>
        {props.users.filter(x => !!x).map((user, key) => (
            <UserRow {...{...user, key}} />
        ))}
        </tbody>
    </Table>
));

const Component = compose(
    connect(state => ({ users: R.pathOr([],["apiGw","admin","users"],state) }),{ apiGwUsersGet }),
    withRouter,
    withState('errorCode','setErrorCode',undefined),
    withState('isLoading','setIsLoading',false),
    withFormik({
        mapPropsToValues: () => {
            const values = {};
            return values;
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: values => {
            const errors = {} ;
            return errors;
        },
        handleSubmit: async (values, formikBag) => {
            const {error} = await new Promise((resolve, reject) => {
                formikBag.props.setIsLoading(true);
                formikBag.props.apiGwUsersGet({resolve, reject});
            });
            if (error) {
                formikBag.props.setErrorCode(error.code);
            }
            formikBag.props.setIsLoading(false);
        },
        displayName: formSchema.$id,
    }),
    setPropTypes({
        values: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    }),
)(({ handleSubmit, disabled, errorCode, ...props }) => {
    const {stage, userPoolId, region} = window.config;
    return (
        <StyledContainer>
            <h1>API Users</h1>
            <hr/>
            <StyledForm className="p-0">
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md="2">
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href={encodeURI(`https://${region}.console.aws.amazon.com/apigateway/home?region=${region}#/api-keys`)}>
                                API Keys
                            </a>
                        </Col>
                        <Col md="2">
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href={encodeURI(`https://${region}.console.aws.amazon.com/cognito/users/?region=${region}#/pool/${userPoolId}/users/`)}>
                                Cognito
                            </a>
                        </Col>                                                
                        <Col md="2">
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href={encodeURI(`https://${region}.console.aws.amazon.com/dynamodb/home?region=${region}#tables:selected=healthpoint-fhir-portal-${stage}-customers;tab=items`)}>
                                Dynamo Users
                            </a>
                        </Col>
                        <Col md="2">
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href={encodeURI(`https://${region}.console.aws.amazon.com/sns/v3/home?region=${region}#/topic/arn:aws:sns:${region}:851453851513:healthpoint-fhir-portal-${stage}`)}>
                                SNS
                            </a>
                        </Col>
                        <Col md="2">
                            <Button type={'submit'}>Load</Button>
                        </Col>
                    </Row>
                </Form>
            </StyledForm>
            <Container>
                <LoadingTable {...props} />
            </Container>
        </StyledContainer>
    );
});
export default Component;
