import AWS from 'aws-sdk';
import {CognitoUser, CognitoUserPool} from 'amazon-cognito-identity-js';
import {getUserPool} from "./cognito-promises/config";
const {environ} = window ;
const {config} = window ;
console.dir({ config,environ });

AWS.config.region = config.region; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.identityPoolId
});

const poolData = {
  UserPoolId:  config.userPoolId, // Your user pool id here
  ClientId: config.userPoolClientId,
};
const userPool = new CognitoUserPool(poolData);

export const getSession = async currentUser =>
    new Promise((resolve, reject) => {
      console.dir({ currentUser });
      currentUser.getSession((err, session) =>{
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });

export const signUpApi = async (username, password, attributeList) =>
    new Promise((resolve, reject) => {
      userPool.signUp(username, password, attributeList, null, (err, res) => {
        console.dir({err,res});
        if (err) {
          if (err.code === 'UsernameExistsException') {
            const userData = {
              Username: username,
              Pool: userPool,
            };
            const cognitoUser = new CognitoUser(userData);
            const isExists = true;
            resolve({ cognitoUser, isExists });
          } else {
            reject(err);
          }
        } else {
          const isExists = false;
          const { cognitoUser } = res;
          resolve({ cognitoUser, isExists });
        }
      });
    });

export const confirmApi = async (cognitoUser, verificationCode) =>
    new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(verificationCode, true, (err, res) => {
        if (err) {
          if (err.code==="ExpiredCodeException") {
            resolve({isExpiredCode:true});
          } else {
            reject(err);
          }
        }
        resolve({isConfirmed:true});
      });
    });

export const loginApi = async (cognitoUser, authenticationDetails) =>
    new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: userSession => {
            resolve( {userSession} );
        },
        onFailure: err => {
            console.dir({err});
            if (err.code==="UserNotConfirmedException") {
                err.message = "User not confirmed. Please check you e-mail.";
            }
            reject(err);
        },
      });
    });


export const updateAttributesApi = async (cognitoUser,attributeList) =>
    new Promise((resolve, reject) => {
        cognitoUser.updateAttributes(attributeList,(err, res) => {
            if (err) {
                reject(err);
            }
            resolve(res);
        });
    });

export const resendConfirmationCodeApi = async email =>
    new Promise((resolve, reject) => {
        const userData = {
            Username: email,
            Pool: getUserPool()
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.resendConfirmationCode(function(err, res) {
            if (err) {
                reject(err);
            }
            resolve(res);
        });
    });


