import * as R from 'ramda';
import createHistory from 'history/createBrowserHistory';
import * as PasswordValidator from 'password-validator';
import { SchemaType } from './constants';

export const listToMap = (list, key) => {
  const indexMap = R.reduce((a, b) => R.assoc(b[key], b, a), {})(list);
  return indexMap;
};

// R.assocPath only works with objects.
const updateObj = (path, val, ctx) => {
  const head = R.cond([
    [x => !isNaN(parseInt(x, 10)), x => parseInt(x, 10)],
    [R.T, R.identity],
  ])(R.head(path));
  const tail = R.tail(path);
  const update = R.curry(R.is(Number, head) ? R.update : R.assoc);
  const newCtx = ctx[head] || update(head, null, ctx)[head];
  const newVal = tail.length > 0 ? updateObj(tail, val, newCtx) : val;
  const newObj = update(head, newVal, ctx);
  return newObj;
};

// direct path update like R.assocPath but also handling arrays
export const updateObjField = (fieldName, value, obj = {}) =>
  updateObj(fieldName.split('.'), value, obj);

export const unflattenObj = (values, currentObj = {}) => {
  const updatedObj = R.reduce(
    (acc, key) => updateObj(key.split('.'), values[key], acc),
    currentObj,
    Object.keys(values)
  );
  return updatedObj;
};

// treat null should be done on the server
/* eslint no-param-reassign:0 */
export const treatNull = val => {
  if (val && typeof val === 'object') {
    Object.keys(val).forEach(key => {
      val[key] = treatNull(val[key]);
    });
    return val;
  }
  return val === null ? undefined : val;
};

const isObject = obj => !(obj instanceof Date)  && (typeof obj === 'object')

/* eslint no-param-reassign:0 */
// for formik
export const flattenToValues = (
  obj,
  terminalRegexp = undefined,
  path = [],
  values = {}
) => {
  if (obj && isObject(obj)) {
    Object.keys(obj).forEach(key => {
      const thisKey = [...path, key].join('.');
      if (terminalRegexp && thisKey.match(terminalRegexp)) {
        values[thisKey] = treatNull(obj[key]);
      } else {
        flattenToValues(obj[key], terminalRegexp, path.concat([key]), values);
      }
    });
  } else {
    values[path.join('.')] = treatNull(obj);
  }
  return values;
};

export const getSchema = refData => name => {
  const schema = R.path(['schemas', name], refData);
  if (!schema) {
    throw new Error(`Unknown schema name : ${name}`);
  }
  return schema;
};

export const getSchemaFromPath = (baseSchema, path) => {
  let schema = baseSchema;
  for (let i = 0; i < path.length; i += 1) {
    switch (schema.type) {
      case SchemaType.array:
        if (!schema.items) {
          throw new Error(
            `path ${path.join(
              '.'
            )} in schema ${baseSchema.$id} is an array and must have an items declaration`
          );
        }
        schema = schema.items;
        break;
      case SchemaType.object:
        if (!schema.properties) {
          throw new Error(
            `path ${path.join(
              '.'
            )} in schema ${baseSchema.$id} is an array and must have an items declaration`
          );
        }
        schema = schema.properties[path[i]];
        break;
      default:
        throw new Error(
          `${baseSchema.$id}:${path.join('.')} => unknwon type ${schema.type}`
        );
    }
    if (!schema) {
      throw new Error(
        `path ${path.join('.')} not found in schema ${baseSchema.$id}`
      );
    }
  }
  return schema;
};

export const getSchemaErrorMessage = (error, validator, obj = {}) => {
  switch (error.keyword) {
    case 'required':
      return {
        key: error.params.missingProperty,
        message: 'Required field',
      };
    case 'minLength':
      return {
        key: error.dataPath.slice(1),
        message: 'Required field',
      };
    case 'format':
      return {
        key: error.dataPath.slice(1),
        message: 'Incorrect format',
      };
    case 'type':
      return {
        key: error.dataPath.slice(1),
        message: 'Incorrect type',
      };
    default:
      throw new Error(
        `Unsupported jsonschema validation keyword : ${error.keyword} [validator:${validator}, obj:${obj}`
      );
  }
};

export const validateJsonschemaFormik = (obj, validator) => {
  const isValid = validator(obj);
  const errors = {};
  if (!isValid) {
    validator.errors.forEach(error => {
      const { key, message } = getSchemaErrorMessage(error, validator, obj);
      errors[key] = message;
    });
  }
  return errors;
};

export const mapIndexed = R.addIndex(R.map);

export const urlQueryFromParamsObject = params =>
  R.isNil(params) || R.isEmpty(params)
    ? ''
    : `?${Object.keys(params)
        .filter( key => !!params[key] )
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
        .join('&')}`;

export class ExtendableError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class RestError extends ExtendableError {
  constructor(m, res) {
    super(m);
    this.res = res;
  }
}

export const getQueryString = () => {
  const { search: q } = window.location

  if (!q) return {}

  return (/^[?#]/.test(q) ? q.slice(1) : q)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value) : 'c';
        return params;
      }, {})
};

export const toTitleCase = (string) => {
  let firstLetter = string[0]
  let allOtherLetters = string.slice(1)

  return firstLetter.toUpperCase() + allOtherLetters.toLowerCase()
};

export const history = createHistory();

export const isEmptyOrNil = x => R.isEmpty(x) || R.isNil(x)

export const passwordSchema = new PasswordValidator();
passwordSchema.is().min(8).has().uppercase().has().lowercase().has().digits();
