import React from 'react';
import {StyledContainer} from "../../style";
import {Alert} from "reactstrap";

const Component = () => (
    <StyledContainer>
        <h1>Welcome to the Healthpoint API Gateway</h1>
        <Alert color="warning">
            <h4 className="alert-heading text-center">
                Browser not supported
            </h4>
            <p className="text-center">
                Thank-you for your interest in our API. Our portal doesn't support your browser.
                You might want to try{' '}
                <a href="https://www.google.com/chrome/">Chrome</a> or{' '}
                <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>.
            </p>
        </Alert>
    </StyledContainer>
);

export default Component;
