// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React from 'react';
import ReactDOM from 'react-dom';
import * as R from 'ramda';
import {compose, lifecycle,setPropTypes} from "recompose";
import {Route, Switch, Redirect} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import {Provider, connect} from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import PropTypes from 'prop-types';
import 'babel-polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import Home from 'pages/Home';
import InternetExplorer from 'pages/InternetExplorer';
import DashboardPage from 'pages/DashboardPage';
import ApiOverview from 'pages/ApiOverview';
import GetStarted from 'pages/GetStarted';
import AdminUsersPage from 'pages/AdminUsersPage';
import TermsAndConditions from 'pages/TermsAndConditions';
import ApiUpdates from 'pages/ApiUpdates';
import DocumentationPage from 'pages/DocumentationPage';
import LoginPage from 'pages/LoginPage';
import PasswordChangePage from 'pages/PasswordChangePage';
import PasswordResetPage from 'pages/PasswordResetPage';
import RegistrationPage from 'pages/RegistrationPage';
import OpenApiPage from 'pages/OpenApiPage';
import NavBar from 'components/NavBar';
import {configureStore} from './common/state';
import {appStart} from './common/state/ducks/awsCognito/actions';
import {history} from './common/utils';

export const store = configureStore();

const isIE = () => {
    const ua = navigator.userAgent;
    const  is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    return is_ie;
}

export const AuthRoute = ({component: Component, ...props}) => {
    const {isPagePublicOnly,isAdminPage,isAdminUser,email,userSession} = props;
    if (R.isEmpty(userSession)) {
        return (<Component {...props} />);
    }
    if (email) {
        const isUserPageAuthed = !isAdminPage || isAdminUser ;
        return (<Route {...props} render={(props) => (
            isUserPageAuthed
                ? <Component {...props} />
                : <Redirect to="/"/>
        )}/>);
    } else {
        return (<Route {...props} render={(props) => (
            isPagePublicOnly
                ? <Component {...props} />
                : <Redirect to="/login"/>
        )}/>);
    }
};

const App = compose(
    connect(
        ({ cognito }) => ({
            userSession: cognito.userSession,
            isAdminUser: cognito.isAdminUser,
            email: R.path(["accountDetails","email"],cognito)
        }),
        { appStart }
    ),
    lifecycle({
        componentWillMount() {
            this.props.appStart();
        },
        componentDidUpdate() {
            window.scrollTo(0, 0);
        }
    }),
    setPropTypes({
        userSession: PropTypes.object,
        isAdminUser: PropTypes.bool
    }),
)(({userSession,isAdminUser,email}) => (
    <ConnectedRouter history={history}>
        <React.Fragment>
            <ReduxToastr
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
            />
            <NavBar/>
            <div className="container">
                {isIE() && <InternetExplorer/>}
                {!isIE() &&
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/get-started" component={GetStarted}/>
                    <Route path="/api-overview" component={ApiOverview}/>
                    <Route path="/updates" component={ApiUpdates}/>

                    <AuthRoute path="/admin" {...{component: AdminUsersPage, email, userSession, isAdminUser, isAdminPage:true }}/>
                    <AuthRoute path="/open-api" {...{component: OpenApiPage, email, userSession}}/>
                    <AuthRoute path="/documentation" {...{component: DocumentationPage, email, userSession}}/>
                    <AuthRoute path="/dashboard" {...{component: DashboardPage, email, userSession}}/>
                    <AuthRoute path="/terms-and-conditions" {...{component: TermsAndConditions, email, userSession}}/>
                    <AuthRoute path="/login" {...{component: LoginPage, email, userSession, isPagePublicOnly: true}}/>
                    <AuthRoute path="/register" {...{component: RegistrationPage, email, userSession, isPagePublicOnly: true}}/>
                    <AuthRoute path="/password-reset" {...{component: PasswordResetPage, email, userSession, isPagePublicOnly: true}}/>
                    <AuthRoute path="/password-change" {...{component: PasswordChangePage, email, userSession, isPagePublicOnly: true}}/>
                    <Route component={({userSession}) => (R.isNil(userSession) ?
                            (<Redirect to={"/login"}/>) :
                            (<Redirect to={"/"}/>)
                    )}/>
                </Switch>
                }
            </div>
        </React.Fragment>
    </ConnectedRouter>
));

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('main')
);

