// import * as R from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import {compose, setPropTypes} from 'recompose';
import {NavLink} from "react-router-dom";

const RegistrationPage = compose(
    setPropTypes({
        keyInfo: PropTypes.object,
    })
)(props => {
    const mailto = `mailto:${props.isRegistered}}`;
    return (<div>
        <h1>Thank you for registering!</h1>
        <hr/>
        <p>
            We have e-mailed you at
            {' '}<a target="_blank" rel="noopener noreferrer" href={mailto}>{props.isRegistered}</a>{' '}
            a link which you can click on to confirm your e-mail address,
            then you can <NavLink to="/login">log in</NavLink>.
        </p>
        <p>
            Please also check your spam folders.
        </p>
    </div>);
});

export default RegistrationPage;
