const {config} = window;
let apiGwClient;
export const apiGwClientInit = async ({ accessKeyId, secretAccessKey, sessionToken } = {}) => {
  apiGwClient = await window.apigClientFactory.newClient({
    accessKey: accessKeyId,
    secretKey: secretAccessKey,
    sessionToken: sessionToken,
    region: config.region
  });
  return apiGwClient;
};

export const apiGwSignin = async () => {
  console.log("apiGwSignin");
  const r = await apiGwClient.post('/signin', {}, {}, {});
  if (r.status!==200) {
    throw new Error(`POST /signin failed: ${r.statusText}`);
  }
  return r.data ;
};

export const apiGwTncAccept = async params => {
  const r = await apiGwClient.post('/tnc-accepted', {} , params, {});
  if (r.status!==200) {
    throw new Error(`POST /tncs-accepted failed: ${r.statusText}`);
  }
  return r.data ;
};

export const apiGwUsagePlanGet = async () => {
  const r = await apiGwClient.get('/usage-plan', {}, {}, {});
  if (r.status!==200) {
    throw new Error(`GET /usage-plan failed: ${r.statusText}`);
  }
  return r.data ;
};

// FIXME
export const apiGwFetchUsage = async (usagePlanId,startDate,endDate) => {
  const start = startDate.toJSON().split('T')[0];
  const end   = endDate.toJSON().split('T')[0];
  const r =  await apiGwClient.get('/usage-plan/' + usagePlanId + '/usage', { start, end }, {});
  if (r.status!==200) {
    throw new Error(`GET /usage-plan failed: ${r.statusText}`);
  }
  return r.data ;
};

export const apiGwGetUsers = async () => {
  const r =  await apiGwClient.get('/users');
  console.dir({ r });
  if (r.status!==200) {
    throw new Error(`GET /users failed: ${r.statusText}`);
  }
  return r.data ;
};

