import React from 'react';
import PropTypes from 'prop-types';
import {compose, setPropTypes, withHandlers, lifecycle, withState} from "recompose";
import moment from "moment";
import {connect} from "react-redux";
import {withFormik} from "formik";
import {Col, Form, Row} from "reactstrap";
import {StyledContainer,StyledForm} from "../../style";
import {apiGwApiUsageGet} from "../../common/state/ducks/awsApiGw/actions";
import {InputType, SchemaType} from "../../common/utils/constants";
import UsageChart from "./UsageChart";
import FormField from '../../components/terminal/FormField';

const formSchema = {
    $id: 'ApiUsageForm',
    description: 'Filter usage',
    type: SchemaType.object,
    properties: {
        startDate: {
            label: 'Start Usage Date',
            description: '',
            type: 'date',
            inputType: InputType.DATE,
        },
        endDate: {
            label: 'End Usage Date',
            description: '',
            type: 'date',
            inputType: InputType.DATE,
        },
    },
};

const Usage = compose(
    setPropTypes({
        usagePlans: PropTypes.array.isRequired,
    }),
    connect(
        () => ({}),
        { apiGwApiUsageGet }
    ),
    withState('usages','setUsages',null),
    withHandlers({
        getUsages: props => (usagePlans,startDate,endDate) => {
            new Promise((resolve, reject) => {
                props.apiGwApiUsageGet({
                    usagePlans,
                    startDate,
                    endDate,
                    resolve,
                    reject,
                });
            }).then(usages => {
                props.setUsages( usages );
            });
        },
    }),
    withFormik({
        mapPropsToValues: () => ({
            endDate:  moment().add(1, 'day').startOf('day').toDate(),
            startDate: moment().subtract(1, 'month').toDate(),
        }),
        validateOnChange: true,
        validateOnBlur: true,
        validate: values => {
            // console.dir({values});
            const errors = {} ;
            return errors;
        },
        handleSubmit: async (values, formikBag) => {
            const { startDate, endDate } = values ;
            formikBag.props.getUsages(formikBag.props.usagePlans, startDate, endDate);
        },
        displayName: formSchema.$id,
    }),
    withHandlers({
        schemaFieldChanged: props => (fieldName, value) => {
            const { startDate, endDate } = props.values ;
            console.dir({ startDate, endDate ,fieldName, value});
            props.getUsages(props.usagePlans, startDate, endDate);
        },
    }),
    lifecycle({
        componentDidMount() {
            const {usagePlans} = this.props;
            const endDate = moment().add(1, 'day').startOf('day').toDate();
            const startDate = moment().subtract(1, 'month').toDate();
            //console.dir({ startDate,endDate });
            this.props.getUsages(usagePlans, startDate, endDate);
        },
    }),
)(({usages, handleSubmit, ...props}) => (
    <StyledContainer>
        {usages &&
        <UsageChart {...{
            usages,
            startDate: props.values.startDate,
            endDate: props.values.endDate,
        }}/>}
        <StyledForm>
            <Form onSubmit={handleSubmit} autoComplete="on">
                <Row>
                    <Col md="4">
                        <FormField
                            disabled
                            {...props}
                            baseSchema={formSchema}
                            path={['startDate']}
                        />
                    </Col>
                    <Col md="4">
                        <FormField
                            disabled
                            {...props}
                            baseSchema={formSchema}
                            path={['endDate']}
                        />
                    </Col>
                </Row>
            </Form>
        </StyledForm>
    </StyledContainer>
));
export default Usage;
