import { all, call, put, takeLatest } from 'redux-saga/effects';
import jwt_decode from "jwt-decode";
import AWS from "aws-sdk";
import * as R from 'ramda';
import moment from "moment";
import { AwsApiInteractions } from './types';
import {
  apiGwClientInit,
  apiGwSignin,
  apiGwUsagePlanGet,
  apiGwFetchUsage,
  apiGwGetUsers, apiGwTncAccept,
} from '../../api/apiAwsApiGw';
import { sagaEntry } from '../../sagas/utils';
import {getCurrentUserSession} from "common/state/api/cognito-promises/config";


const awsCredentialsRefresh = credentials => new Promise(
    (resolve, reject) => credentials.refresh((error) => {
    if (error) {
      return reject(error)
    }
    resolve();
}));

const samplePaths = [
  "Service_resource_sample_-_Allied_Health_service",
  "Service_resource_sample_-_Cancer_support_service",
  "Service_resource_sample_-_Community_Health_service",
  "Service_resource_sample_-_Dentistry_service",
  "Service_resource_sample_-_GP_practice",
  "Service_resource_sample_-_Mental_Health_and_Addictions_with_multiple_locations",
  "Service_resource_sample_-_Mental_Health_and_Addictions_with_multiple_locations_and_hours_for_each_location",
  "Service_resource_sample_-_Mental_Health_and_Addictions_with_no_locations",
  "Service_resource_sample_-_Midwifery_service",
  "Service_resource_sample_-_Optometry_service",
  "Service_resource_sample_-_Pharmacy",
  "Service_resource_sample_-_Private_service",
  "Service_resource_sample_-_Private_surgical_service",
  "Service_resource_sample_-_Public_service",
  "Service_resource_sample_-_Social_service",
  "Service_resource_sample_-_Multi_branch_Cancer_and_Community",
  "Service_resource_sample_-_Multi_branch_Community_and_Mental_Health_and_Addictions_and_Social",
  "Service_resource_sample_-_Multi_branch_Private_Specialty_and_Allied_Health",
  "Service_resource_sample_-_Multi_branch_Public_Specialty_and_Mental_Health_and_Addictions",

  "Location_resource_sample_-_23_Edwin_Street",
  "Location_resource_sample_-_Pleasantville_Surgical_Centre",
  "Location_resource_sample_-_Supercity_Hospital",

  "PractitionerRole_sample_-_Dental_team",
  "PractitionerRole_sample_-_GP",
  "PractitionerRole_sample_-_Midwife",
  "PractitionerRole_sample_-_Optometry_team",
  "PractitionerRole_sample_-_Specialist_associated_with_private_service",
  "PractitionerRole_sample_-_Specialist_associated_with_private_surgical_service",
  "PractitionerRole_sample_-_Specialist_associated_with_public_service",

  "Practitioner_resource_sample_-_Dental_team",
  "Practitioner_resource_sample_-_GP",
  "Practitioner_resource_sample_-_Midwife",
  "Practitioner_resource_sample_-_Optometry_team",
  "Practitioner_resource_sample_-_Specialist",
];




const {config} = window ;

const onApiGwInit = function*({ payload }) {
  const { userSession } = payload ;
  const idToken = userSession.idToken.jwtToken ;
  const params = {
    IdentityPoolId: config.identityPoolId,
    Logins: {
      [`cognito-idp.${config.region}.amazonaws.com/${config.userPoolId}`]: idToken
    }
  };
  const decodedIdToken = jwt_decode(idToken);
  const preferred_role = decodedIdToken['cognito:preferred_role'];
  if (preferred_role) {
    params.RoleArn = preferred_role;
  }
  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);
  yield call(awsCredentialsRefresh,AWS.config.credentials);

  const accessKeyId = AWS.config.credentials.accessKeyId ;
  const secretAccessKey = AWS.config.credentials.secretAccessKey ;
  const sessionToken = AWS.config.credentials.sessionToken  ;
  if (!(accessKeyId && secretAccessKey && sessionToken )) {
    throw new Error("No AWS credentials for API Gateway calls");
  }
  yield call(apiGwClientInit,{accessKeyId, secretAccessKey, sessionToken});
  const user = yield call(apiGwSignin);
  const order = [
      "HealthpointFHIRProdPlan",
      "HealthpointFHIRUATPlan",
      "HealthpointFHIRUATEnhancedPlan",
      "HealthpointFHIRTestPlan"
  ];
  const usagePlans = (yield call(apiGwUsagePlanGet))
      .filter(x => R.includes(x.name,order) )
      .sort((a,b)=>order.indexOf(a.name)-order.indexOf(b.name));
  const parseJSON = (path,json) => {
    try {
      return JSON.parse(json);
    } catch (e) {
      throw Error(`${path} : ${e.message}`);
    }
  }
  const fetchPath = async path => {
    // console.log(`path = ${path}`);
    const json = await (await fetch(`/samples/${path}.json`)).text();
    const xml  = await (await fetch(`/samples/${path}.xml`)).text();
    // console.dir({path, json,xml});
    return {
      resource: path.split("_")[0].substr(9),
      useCase: path.split("-")[1].replace(/_/g," ").substr(1),
      json: parseJSON(path,json),
      xml
    };
  };
  const samples = yield all(samplePaths.map(fetchPath));
  yield put({
    type: `${AwsApiInteractions.API_GW_INIT}_RES`,
    payload: {user, usagePlans, samples},
  });
};

const onAcceptTnc = function*({ payload }) {
  const {resolve} = payload;
  // const user = getCurrentUserSession();
  const data = yield call(apiGwTncAccept,{tncAccepted});
  const {tncAccepted} = data;
  yield put({
    type: `${AwsApiInteractions.ACCEPT_TNCS}_RES`,
    payload: {tncAccepted},
  });
  resolve({tncAccepted});
};

const onApiGetUsage = function*({ payload }) {
  const {usagePlans, startDate, endDate, resolve} = payload;
  const usageDatas = yield all(usagePlans.map(s => call(apiGwFetchUsage, s.id, startDate, endDate)));
  const usagePlanDatas = usagePlans.map(x => ({name: x.name , items: []}) );
  if (usagePlanDatas != null) {
    for (const i in usageDatas) {
      usagePlanDatas[i] = R.mergeRight( usagePlanDatas[i], usageDatas[i] );
    }
  }
  yield put({
    type: `${AwsApiInteractions.API_USAGE_GET}_RES`,
    payload: usagePlanDatas,
  });
  resolve(usagePlanDatas);
};

const onApiGetUsers = function*({ payload }) {
  const {resolve} = payload;
  const users = yield call(apiGwGetUsers);
  yield put({
    type: `${AwsApiInteractions.API_USERS_GET}_RES`,
    payload: users,
  });
  resolve(users);
};

/* eslint-disable func-names */
export function* watch() {
  yield takeLatest(
      `${AwsApiInteractions.API_GW_INIT}_REQ`,
      sagaEntry(AwsApiInteractions.API_GW_INIT, onApiGwInit)
  );
  yield takeLatest(
      `${AwsApiInteractions.ACCEPT_TNCS}_REQ`,
      sagaEntry(AwsApiInteractions.ACCEPT_TNCS, onAcceptTnc)
  );
  yield takeLatest(
      `${AwsApiInteractions.API_USAGE_GET}_REQ`,
      sagaEntry(AwsApiInteractions.API_USAGE_GET, onApiGetUsage)
  );
  yield takeLatest(
      `${AwsApiInteractions.API_USERS_GET}_REQ`,
      sagaEntry(AwsApiInteractions.API_USERS_GET, onApiGetUsers)
  );
}
