import React from 'react';
import * as R from 'ramda';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { NavLink,Link } from 'react-router-dom';
import whileLoading from '../../components/whileLoading';
import {StyledContainer} from "../../style";
import {isEmptyOrNil} from "../../common/utils";
import AccountDetails from './AccountDetails';
import Environments from "./Environments";
import Usage from "./Usage";

export default compose(
    connect(
        state => ({
            apiGw: state.apiGw,
            accountDetails: state.cognito.accountDetails
        }),
        null
    ),
    whileLoading(props => isEmptyOrNil(props.accountDetails) || isEmptyOrNil(props.apiGw) ),
)(({accountDetails, apiGw, showModal, ...props }) => {
    const apiKey = R.path(["user","apiKey","value"],apiGw) ;
    const isActive = apiGw.isActive && apiGw.tncAccepted ;
    let alertContent ;
    if (isActive) {
        alertContent = (
            <Alert  className="text-center" color="secondary">
                <h4 className="alert-heading">
                    Your account is active
                </h4>
                <p>
                    You can now call the API using the API key <code>{apiKey}</code>.
                </p>
                <p>
                    Read our <Link to={"/documentation"}>documentation</Link> to learn about our API
                    and use <Link to={"/open-api"}>Open API</Link> for a detailed API description and
                    to try API calls.
                </p>
            </Alert>
        );
    } else if (apiGw.tncAccepted) {
        alertContent = (
            <Alert color="secondary">
                <h4 className="alert-heading text-center">
                    Terms and conditions accepted
                </h4>
                <p className="text-center">
                    You may view the terms and conditions <NavLink to="/terms-and-conditions">here</NavLink>.
                    We will e-mail you when your API key has been activated.
                </p>
            </Alert>
        );
    } else {
        alertContent = (
            <Alert color="secondary">
                <h4 className="alert-heading text-center">
                    Thank-you for registering
                </h4>
                <p className="text-center">
                    Once you have accepted the
                    {' '}<NavLink to={"/terms-and-conditions"}>terms and conditions</NavLink>{' '}
                    we will be in touch to best understand your requirements.
                </p>
            </Alert>
        );
    }
    return (
        <StyledContainer>
            <br/>
            {alertContent}
            {isActive &&
            <div>
                <br/>
                <h1>Environments</h1>
                <br/>
                <Environments usagePlans={apiGw.usagePlans}/>
                <br/>
                <h1>Usage</h1>
                <br/>
                <Usage usagePlans={apiGw.usagePlans}/>
                <br/>
            </div>
            }
            <h1>Account Details</h1>
            <p>
                Thank you for providing us with your details.
                We store these securely and do not disclose them to any third party.
            </p>
            <AccountDetails  {...{accountDetails, apiGw, ...props}} />
         </StyledContainer>
    );
});
