import { all, fork } from 'redux-saga/effects';
import { watch as apiGw } from '../ducks/awsApiGw/saga';
import { watch as cognito } from '../ducks/awsCognito/saga';


function* rootSaga() {
  yield all([
    fork(apiGw),
    fork(cognito),
  ]);
}
export default rootSaga ;
