import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import PropTypes from "prop-types";
import React from "react";
import {compose, setPropTypes, withState } from "recompose";
import {Row,Col,Container,Button} from "reactstrap";
import {StyledContainer} from '../../style';
import resources from "../../common/utils/fhir-resources";
import {HashLink as Link} from "react-router-hash-link";

/*
import { CopyToClipboard } from 'react-copy-to-clipboard';
const SyntaxHighlighter1 = ({children,copy}) => (
    <CopyToClipboard
        TooltipProps={{ title: "Copied XYZ!", leaveDelay: 3000 }}
    >
        <SyntaxHighlighter>
            {children}
        </SyntaxHighlighter>
    </CopyToClipboard>
);*/

const SampleRow = compose(
    setPropTypes({ resource: PropTypes.string.isRequired }),
    setPropTypes({ useCase: PropTypes.string.isRequired }),
    withState('isShow' ,'setIsShow' ,false),
)(({ resource, useCase, isShow, setIsShow, overrideColor, children }) => {
    const name = resources[resource].name ;
    const color = "#6c757d" ;
    return (
    <div>
      <Row className="sample py-2">
          <Col md="3">
              <a target="_blank" rel="noopener noreferrer"
                 href={`https://www.hl7.org/fhir/${resource}.html`}>
                  <h5 style={{color}}>{name}</h5>
              </a>
          </Col>
        <Col md="8">
          <h5 style={{color}}>{useCase}</h5>
        </Col>
        <Col md="1">
            <Button outline color="secondary" size="sm" type="checkbox" active={isShow}
                    onClick={() => setIsShow(!isShow)} >{isShow?"Hide":"Show"}</Button>
        </Col>
      </Row>
      {isShow &&
      <Row className="sample py-2">
          <Col md="12">
              {children}
          </Col>
      </Row>}
    </div>
  );
});

const ApiSamples = compose(
    setPropTypes({
        apiKey: PropTypes.string.isRequired
    }),
)(({apiKey}) => (
    <div>
        <p>The Healthpoint API uses a GET method to return results, with example use cases shown in the table below.</p>
        <p>The majority of use cases will be satisfied using a combination of the parameters branch + region
            within the <strong style={{color: "#3DAE64"}}>Healthcare service</strong> resource.
            If you are looking for a specific service an _id search would be recommended,
            particularly
            if your use case starts with a <strong style={{color: "#3DAE64"}}>Healthcare
                service</strong> resource
            and
            links off to other resources (e.g. to the <strong
                style={{color: "#3D87AE"}}>Location</strong>, <strong
                style={{color: "#643DAE"}}>Practitioner</strong> or <strong
                style={{color: "#9D3DAE"}}>PractitionerRole</strong> resources).</p>
        <p>The following examples are for our UAT (user acceptance testing environment) but the
            format is the same for the production API site. Query parameters should be URL-encoded
            e.g. <strong>Nelson Marlborough</strong> should appear as <strong>Nelson%20Marlborough</strong>.
        </p>
        <p>Use the <Link to={"/open-api"}>Open API</Link> client to see documention of the input options for
            each parameter and to trial more complex use cases.
        </p>
        <p>
            <strong>Please ensure you reference that Healthpoint is the source of information anywhere that you use it.
            Example text: "This specialist profile information has been provided by
            https://www.healthpoint.co.nz, helping people better understand
            and use New Zealand health services</strong>."
        </p>
        <br/>
        <StyledContainer>
            <Row key={1}>
                <Col className="px-0">
                    <div className="p-3">
                        <Container>
                            <Row className="background_color_lighter_grey sample py-2">
                                <Col md="3">
                                    <h3>FHIR<sup>&reg;</sup> Resource</h3>
                                </Col>
                                <Col md="8">
                                    <h3>Use Case</h3>
                                </Col>
                            </Row>

                            <SampleRow resource={"HealthcareService"} useCase={"Mental health and addiction services"}>
                                <p><strong style={{color: "#ca6623"}}>Mental health & addiction services</strong>&nbsp; 
                                    can be searched via the following parameters to return relevant programmes that meet all criteria:
                                </p>
                                <ul>
                                    <li>programme-region</li>
                                    <li>programme-area or programme-type</li>
                                    <li>programme-age-groups</li>
                                    <li>programme-referral-type</li>
                                </ul>
                                <p>Request URL example ((Mental Health & Addictions + Youth + Southland + Mental Health):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService?branch-code=ngomentalhealth&programme-region=Southland&programme-area=Mental%20health&programme-age-groups=Youth%20%2F%20rangatahi"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            <SampleRow resource={"HealthcareService"} useCase={"Branch and region search to return a list of services"}>
                                <p>Request URL example (GPs / Accident & Urgent Medical Care + Wellington + JSON):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService?branch-code=primary&region=Southland"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            <SampleRow resource={"HealthcareService"} useCase={"Branch and DHB region search to return a list of services"}>
                                <p>Request URL example (Public hospital services + Southern + XML):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService?branch-code=primary&dhb-region=Southern"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            <SampleRow resource={"HealthcareService"} useCase={"Branch and coordinates (latitude and longitude)"}>
                                <p>This method can be used for any branch and will return the top 10 closest results, with the default searching up to a 20km radius. 
                                    If you need to expand your radius please set radius. 
                                    Please note: Services that do not have a physical location (e.g. helpline) will not appear in these results.
                                </p>
                                <p>Request URL example (Pharmacy+ Coordinates for Fiordland + JSON):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService?latitude=-44.9625917&longitude=168.0490921&radius=200"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            <SampleRow resource={"HealthcareService"} useCase={"Healthpoint Service Type"}>
                                <p>Request URL example (Physiotherapy Services):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService?healthpoint-service-type=Physiotherapy%20Service"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            <SampleRow resource={"HealthcareService"} useCase={"Types of services provided (COVID-19 Vaccination)"}>
                                <p>Request URL example (COVID-19 Vaccination):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService?services-provided-type=COVID-19%20Vaccination"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            <SampleRow resource={"HealthcareService"} useCase={"Specialty (public and private specialist services)"}>
                                <p>Request URL example (Orthopaedics):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService?specialty=Orthopaedics"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            <SampleRow resource={"HealthcareService"} useCase={"Subregion"}>
                                <p>This method can be used for any branch but will only return services that have specified a location for
                                    that service, in that subregion. For example, if you want to find out all the services based in
                                    Ashburton. Please note: The same named subregion may occur in multiple regions (e.g. Chartwell in
                                    Waikato and Chartwell in Wellington).</p>
                                <p>Request URL example (Ashburton + XML):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService?subregion=Ashburton&_format=xml"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            <SampleRow resource={"HealthcareService"} useCase={"Searching on Healthpoint ID"}>
                                <p>If you require a specific{' '}
                                    <strong style={{color: "#3DAE64"}}>Healthcare service</strong>,{' '}
                                    <strong style={{color: "#3D87AE"}}>Location</strong>,{' '}
                                    <strong style={{color: "#643DAE"}}>Practitioner</strong> or{' '}
                                    <strong style={{color: "#9D3DAE"}}>PractitionerRole</strong>
                                    an <i>_id</i> search would be recommended. You may also use
                                    this for example once you know the codes for <strong
                                        style={{color: "#3D87AE"}}>Location</strong> and <strong
                                        style={{color: "#643DAE"}}>Practitioner</strong> resources, located within the <strong
                                        style={{color: "#3DAE64"}}>Healthcare service</strong> resource to bring up the detail of those
                                    associated resources.</p>

                                <p style={{color: "#3DAE64"}}>Request URL example (Healthcare Service resource):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService/_search?_id=hp-service-32242&_format=json&_pretty=true"`}
                                </SyntaxHighlighter>

                                <p style={{color: "#3D87AE"}}>Request URL example (Location resource):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/Location/hp-location-31955"`}
                                </SyntaxHighlighter>

                                <p style={{color: "#643DAE"}}> Request URL example (Practitioner resource):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/Practitioner/hp-prac-14246"`}
                                </SyntaxHighlighter>

                                <p style={{color: "#9D3DAE"}}>Request URL example (Practitioner Role resource):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey}
                                    "https://uat.healthpointapi.com/baseR4/PractitionerRole/hp-pracrole-36200"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            <SampleRow resource={"HealthcareService"} useCase={"Service Changes only"}>
                                <p>Query services that have recently been updated. 
                                    If this is a regular query we recommend doing daily or up to once a week. 
                                    It can be used in combination with all other query parameters. 
                                    Please note UAT does not regularly update so is not ideal for testing this
                                </p>
                                <p>Request URL example (Ashburton + XML):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService?last-updated=gt2021-06-01T01:00:00"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            <SampleRow resource={"HealthcareService"} useCase={"Services no longer active"}>
                                <p>Query services that have been recently removed from the Healthpoint website. 
                                    If this is a regular query we recommend doing daily or up to once a week.</p>
                                <p>Request URL example (Ashburton + XML):</p>
                                <SyntaxHighlighter language="bash" style={dark}>
                                    {`curl -H x-api-key:${apiKey} "https://uat.healthpointapi.com/baseR4/HealthcareService?active=false&last-updated=gt2021-06-01T01:00:00"`}
                                </SyntaxHighlighter>
                            </SampleRow>

                            
                        </Container>
                    </div>
                </Col>
            </Row>
        </StyledContainer>
    </div>
));

export default ApiSamples;
