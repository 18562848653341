import React from 'react';
import {StyledContainer} from "../../style";

const Component = () => (
  <StyledContainer>
      <h1>API Overview</h1>
      <hr/>
      <h2>Healthpoint NZ National Health Services Directory</h2>
      <p>
          <a target="_blank" rel="noopener noreferrer" href="http://www.healthpoint.co.nz">Healthpoint</a>{' '}is the NZ national health services directory.
          Over 450,000 people every month access the Healthpoint website, in addition to the use by DHBs,
          practice management systems, health apps and national helplines.
      </p>
      <p>
      Services profiled on Healthpoint have one or more contributing editors who, using the Healthpoint service structure,
      provide high-quality, detailed information about their service. Services are regularly prompted to review this
      information and currently up to 1,000 service changes occur every month.
      </p>
      <p>
      The Healthpoint processes and national coverage provide a rich, high-quality database of information about NZ health
      and community services.
      </p>
      <h2>What is the {'HL7'}<sup>&reg;</sup> FHIR<sup>&reg;</sup> standard ?</h2>
      <p>
          <a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/summary.html">FHIR<sup>&reg;</sup></a>{' '}
          (Fast Healthcare Interoperability Resources)
          is a specification for exchanging healthcare data in a modern and developer-friendly way.
          There is an emphasis on web standards, implementation and interoperability.
      </p>
      <p>
      FHIR<sup>&reg;</sup> is open source and has a large community of developers and organisations that use and support it,
      including Apple, Microsoft, Google, Amazon and Harvard Medical School.
      </p>
      <p>
      FHIR<sup>&reg;</sup> can be used in the development of everything from mobile apps and websites, cloud communications,
      EHR-based data sharing and server communication in large healthcare organisations.
      </p>
      <h2>Healthpoint FHIR<sup>&reg;</sup> Compatible API</h2>
      <p>
      The Healthpoint API gateway provides an easy method for third party applications, websites and health organisations
      to obtain content from the Healthpoint website, specifically according to their content needs, as they need it.
      Healthpoint supports the third party to ensure optimal usage and implementation.
      The API can be accessed by unlimited users and applications.
      </p>
      <p>
      The Healthpoint API infrastructure is built on{' '}
      <a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/api-gateway">Amazon API gateway</a>{' '}
      according to FHIR<sup>&reg;</sup> guidelines for RESTful API development and authentication.
      </p>
      <p>
      Healthpoint has used the FHIR<sup>&reg;</sup> standard to provide a true reflection of the NZ healthcare service landscape,
      in alignment with the presentation of information in the Healthpoint website,
      by which users can refine to content specific to their requirements.
      </p>
      <h2>Healthpoint FHIR<sup>&reg;</sup> Compatible API - Technical Summary</h2>
      <p>
          The Healthpoint FHIR<sup>&reg;</sup> compatible API is a RESTful implementation of the
          {' '}<a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir">HL7<sup>&reg;</sup> FHIR<sup>&reg;</sup></a>{' '}
          (Fast Healthcare Interoperability Resources) standard for healthcare data exchange.
      </p>
      <p>
      It is built on{' '}<a target="_blank" rel="noopener noreferrer" href="http://hapifhir.io">HAPI FHIR</a>{' '}the FHIR API for Java, an open-source implementation of the
      FHIR<sup>&reg;</sup> specification in Java, and runs on AWS infrastructure to be robust, responsive, reliable, scalable and secure.
      </p>
      <p>
      The FHIR<sup>&reg;</sup>  data model is to build a base set of resources that,
      either by themselves or when combined, satisfy the majority of
      common use cases in healthcare. In modeling Healthpoint data we
      rely on the following FHIR<sup>&reg;</sup> modules:
      </p>
      <ul>
        <li><a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/foundation-module.html">Foundation</a> The basic definitional
      infrastructure on which the rest of the specification is built.</li>
        <li><a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/administration-module.html">Administration</a>{' '}
            Basic resources for tracking patients, practitioner organizations,
            devices, substances, etc. In particular:
          <ul>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/healthcareservice.html">Healthcare Services</a>{' '}
                  Map to a Healthpoint service. e.g. West Doctors</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/location.html">Location</a>{' '}
                  Maps to a Healthpoint service’s location. e.g. 23 Edwin Street, Mt Eden, Auckland</li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/practitioner.html">Practitioner</a>{' '}
                  Maps to a healthcare provider profiled in Healthpoint.
                  This could be a dentist, general practitioner, midwife, optometrist or specialist e.g. Dr Mikayla Quinn.
              </li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/practitionerrole.html">Practitioner Role</a>{' '}
                  Provides information about a healthcare provider, in the context of a service they work at.
              </li>
          </ul>
        </li>
        <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/formats.html">Serialization Formats</a>{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/xml.html">XML</a>{' '}
            and{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/json.html">JSON</a>.
        </li>
        <li><a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/terminology-module.html">Terminology</a>{' '}
                Use and support of terminologies and related artifacts.</li>
        <li><a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/conformance-rules.html">Conformance</a>{' '}
                How to test conformance to the specification, and define implementation guides.</li>
        <li><a target="_blank" rel="noopener noreferrer" href="https://hl7.org/fhir/2018Jan/linked-data-module.html">Linked Data</a>{' '}
                Defines methods of access. For the Healthpoint API this is{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://hl7.org/fhir/2018Jan/http.html">REST.</a></li>
      </ul>
      <p>
          FHIR<sup>&reg;</sup> is the registered trademark of HL7 and is used with the permission of HL7. Use of the FHIR trademark does not constitute endorsement of this product by HL7.
      </p>
        <p>
      Feel free to contact us with any questions
      at <a target="_blank" rel="noopener noreferrer" href="mailto:api@healthpoint.co.nz">api@healthpoint.co.nz</a>
            {' '}or{' '}<a target="_blank" rel="noopener noreferrer" href="tel:+6496300828">(09) 630 0828</a>
        </p>
      <hr/>
  </StyledContainer>
);
export default Component;
