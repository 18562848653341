import React from 'react';
import {Link} from 'react-router-dom';
import {StyledContainer} from "../../style";

const Component = () => (
    <StyledContainer>
        <h1>Welcome to the Healthpoint API Gateway</h1>
        <hr/>
        <div>
            <p>The Healthpoint API provides an easy method for third party applications,
                websites and health organisations to obtain content from the{' '}
                <a target="_blank" rel="noopener noreferrer" href="http://www.healthpoint.co.nz">Healthpoint website</a>,
                specifically according to their content needs, as they need it.
                Healthpoint supports the third party to ensure optimal usage and implementation.
                The API can be accessed by unlimited users and applications.
            </p>
            <p>The Healthpoint API infrastructure is built on{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/api-gateway">Amazon API Gateway</a>{' '}
                according to the HL7<sup>&reg;</sup> FHIR<sup>&reg;</sup> standard for RESTFUL API development and authentication.
            </p>
            <p>Healthpoint has used the <a target="_blank" rel="noopener noreferrer" href="https://www.hl7.org/fhir/">FHIR<sup>&reg;</sup></a>{' '}
                standard to provide a true reflection of the NZ healthcare service landscape,
                in alignment with the presentation of information in the Healthpoint website,
                by which users can refine to content specific to their requirements.
            </p>
            <p><Link to={"/get-started"}>Get started</Link> and{' '}
                <Link to={"/register"}>register</Link> to use the Healthpoint API.
            </p>
            <p>View the <Link to={"/api-overview"}>API overview</Link>.
                Check out our <Link to={"/updates"}>API Updates</Link> for any up and coming changes.
            </p>
            <h2>Contact us</h2>
            <p>Feel free to contact us with any questions
                at <a target="_blank" rel="noopener noreferrer" href="mailto:api@healthpoint.co.nz">api@healthpoint.co.nz</a>{' '}
                or <a target="_blank" rel="noopener noreferrer" href="tel:+6496300828">(09) 630 0828</a>
            </p>
        </div>
        <hr/>
    </StyledContainer>
);

export default Component;
