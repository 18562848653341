import {connect} from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import {compose, setPropTypes,  withState } from "recompose";
import {Row,Col,Container,Button} from "reactstrap";
import JsonView from "react-json-view";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {Link} from "react-router-dom";
import {StyledContainer} from '../../style';
import resources from "../../common/utils/fhir-resources";
import whileLoading from "../../components/whileLoading";

const SampleRow = compose(
    setPropTypes({ sample: PropTypes.object.isRequired }),
    withState('isShowJSON' ,'setIsShowJSON' ,false),
    withState('isShowXML' ,'setIsShowXML' ,false),
)(({ isShowJSON, setIsShowJSON, isShowXML, setIsShowXML, sample }) => {
  const { resourceType } =  sample.json ;
  const { name, color }  = resources[  resourceType ];
  console.dir({ resources, resourceType, name, color });
  return (
    <div>
      <Row className="sample py-2">
        <Col md="3">
          <a target="_blank" rel="noopener noreferrer"
             href={`https://www.hl7.org/fhir/${sample.json.resourceType}.html`}>
            <h5 style={{color}}>{name}</h5>
          </a>
        </Col>
        <Col md="7">
          <h5 style={{color}}>{sample.useCase}</h5>
        </Col>
        <Col md="1">
            <Button outline color="secondary" size="sm" type="checkbox" active={isShowJSON}
                    onClick={() => {
                        if (!isShowJSON) {
                            setIsShowXML(false)
                        }
                        setIsShowJSON(!isShowJSON);
                    }} >JSON</Button>
        </Col>
          <Col md="1">
              <Button outline color="secondary" size="sm" type="checkbox" active={isShowXML}
                      onClick={() => {
                          if (!isShowXML) {
                              setIsShowJSON(false)
                          }
                          setIsShowXML(!isShowXML);
                      }}>XML</Button>
          </Col>
      </Row>
      {isShowJSON &&
      <Row className="sample py-2">
          <Col md="12">
              <JsonView src={sample.json} theme="monokai"/>
          </Col>
      </Row>}
      {isShowXML &&
      <Row className="sample py-2">
          <Col md="12">
              <SyntaxHighlighter language="xml" style={dark}>
                  {sample.xml}
              </SyntaxHighlighter>
          </Col>
      </Row>}
    </div>
  );
});

const DataSamples = compose(
    connect(({apiGw}) => ({apiGw})),
    setPropTypes({
        apiGw: PropTypes.object.isRequired
    }),
    whileLoading(props => !props.apiGw  ),
)(({apiGw, handleSubmit, setIsShowModal, isShowModal, showSample}) => (
    <div>
        <p>You can explore the samples which show examples of our data model.</p>
        <p>To view samples with more than one service result
            (e.g. Region + branch) go to <Link to={"/open-api"}>Open API.</Link>
        </p>
        <StyledContainer>
            <Row key={1}>
                <Col className="px-0">
                    <div className="p-3">
                        <Container>
                            <Row className="background_color_lighter_grey sample py-2">
                                <Col md="3">
                                    <h3>FHIR<sup>&reg;</sup> Resource</h3>
                                </Col>
                                <Col md="9">
                                    <h3>Use Case</h3>
                                </Col>
                            </Row>
                            {apiGw.samples.map((sample, idx) => (
                                <SampleRow key={idx} sample={sample} showSample={showSample}/>
                            ))}
                        </Container>
                    </div>
                </Col>
            </Row>
        </StyledContainer>
    </div>
));

export default DataSamples;
