// import * as R from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, setPropTypes } from 'recompose';
import { Container, Row, Col } from 'reactstrap';
import RegistrationForm from './RegistrationForm';
import { StyledForm } from '../../style';
import {NavLink} from "react-router-dom";

const RegistrationPage = compose(
  setPropTypes({
    keyInfo: PropTypes.object,
  })
)(props => (
  <div>
      <h1>Register</h1>
      <hr/>
      <p>
          Sign up to receive your Healthpoint API key or else{' '}
          <NavLink to="/login">log in</NavLink> if you have already
          registered.
      </p>
    <Container className="p-0">
      <Row key={1}>
        <Col className="">
          <StyledForm className="p-3">
            <RegistrationForm {...{...props}} />
          </StyledForm>
        </Col>
      </Row>
    </Container>
  </div>
));

export default RegistrationPage;
