import { getUser } from './config'

export default function() {
  //console.log("getSession");
  const cognitoUser = getUser()

  //console.dir({cognitoUser });
  if (cognitoUser) {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        console.dir({cognitoUser,session });
        if (err) {
          reject(err)
        } else {
          resolve(session)
        }
      })
    })
  } else {
    throw new Error('no cognitoUser value')
  }
}
