import React from 'react';
import {compose, withState} from "recompose";
import {Button, Card} from "reactstrap";
import {StyledContainer} from "../../style";

const CardCollapse = compose(
    withState('isShow' ,'setIsShow' ,false),
)(({ isShow, setIsShow, title, children }) => (
    <Card className={"p-3"}>
        <span>
            <h5 style={{display: "inline-block"}}>{title}</h5>
            <Button outline color="secondary" size="sm" type="checkbox" active={isShow} className={"pull-right"}
                    onClick={() => setIsShow(!isShow)} >{isShow?"Hide":"Show"}</Button>
        </span>
        {isShow &&
        <div>
            <br/>
            {children}
        </div>}
    </Card>
));

/*
        <p> If you are calling the API via a service, location, practitioner or practionerRole ID you will always get
            only 1 result. Note: This differs to when you are using a resource’s ID for a search in another resource, e.g.
            Searching the service resource by a Location ID.
        </p>
 */

const Component = compose(
    // whileLoading(props => true )
)(() => (
    <StyledContainer>
        <CardCollapse title={"Retrieve by ID"}>
            <p> If you are calling the API via a service, location, practitioner or practionerRole ID you will always get
                only 1 result. Note: This differs to when you are using a resource’s ID for a search in another resource, e.g.
                Searching the service resource by a Location ID.
            </p>
        </CardCollapse>

        <CardCollapse title={"Coordinate searches"}>
            <p> This method can be used for any branch and will return up to 10 results in order of nearest to
                furthest away. The default is to search within a 20km radius.
                Note: Services that do not have a physical location (e.g. Helplines) will not appear in these results.
                This search is most useful for returning results where “nearest to me” is one of the most important
                criteria. E.g. What is the nearest pharmacy.
            </p>
        </CardCollapse>

        <CardCollapse title={"Healthcare service parameter search - excluding mental health and addictions branch"}>
            <p>
                If you are calling the API via a parameter search, you may get more than one result.
                Each call will give you a count of the total number of results but will return no more than 10 results.
                To get the next 10 results you will need to use the URL contained within <code>"relation": "next"</code>.
            </p>
            <p>
                Example: Searching for GP practices and urgent care clinics in the Hutt region (branch = primary and
                region = Hutt)
            </p>
            <br/>
            <span><img alt="" src="/images/mental-health-first.png"/></span>
            <br/>
            <p>Calling this <code>next</code> URL you will also get the next 10 of results, and so on.
                A <code>"relation": "previous"</code> URL will link you back to the previous 10 results.
            </p>
            <br/>
            <span><img alt="" src="/images/mental-health-last.png"/></span>
        </CardCollapse>

        <CardCollapse title={"Healthcare service parameter search - mental health and addictions"}>
            <p>
                The mental health and addictions area of Healthpoint was codesigned with health professionals and
                consumers to accurately reflect the mental health and addictions service landscape within New Zealand.
                This led to a “programme-centric” design within the mental health and addictions services
                profiled in Healthpoint.
                Any given mental health and addictions service may provide one or many programmes and they may
                have differing eligibility criteria. The eligibility to access any of these programmes is based
                on where a person lives, their age, their need and if a referral is required (and who by).
            </p>
            <p>
                The logic within the Healthpoint website to only return service results where a programme matches
                all the selected criteria, has also been applied within the API.
                If you are calling the API via a parameter search for mental health and addictions,
                you may get more than one result. Most Mental health and addictions searches using a combination
                of parameters will give less than 50 results.
            </p>
            <p>
                Each call will give you a count of the total number of results. For mental health and addiction calls,
                each call will return no more than 50 results. Results are throttled to 200. Only a couple of
                combinations
                of parameter searches give over 200 results, so when using the API to return results, always include at
                least a {' '}<code>programme-region</code>{' '}or{' '}<code>programme-type</code>{' '}
                or if using a broad{' '}<code>programme-area</code>, apply other parameters
                alongside it. To test combinations of searches try here:
                {' '}<a href="https://www.healthpoint.co.nz/mental-health-addictions"
                        target="_blank" rel="noopener noreferrer">https://www.healthpoint.co.nz/mental-health-addictions
            </a>.{' '}
                The more parameters you apply the better the search results.
            </p>

            <p>
                Example of Mental Health and Addictions branch search with more than 50 results:
                Searching for Mental Health services in the Hawkes Bay region
                (programme-area= Mental Health and region = Hawkes Bay)
            </p>
            <br/>
            <span><img alt="" src="/images/mental-health-first.png"/></span>
            <br/>
            <p>
                Calling this “next” URL you will also get the next 50 of results, and so on.
                A “previous URL” will link you back to the previous 10 results.
            </p>
            <br/>
            <span><img alt="" src="/images/mental-health-last.png"/></span>
        </CardCollapse>

        <CardCollapse title={"Bulk API calls"}>
            <p> Please contact us if you require a larger number of results per call.
            </p>
        </CardCollapse>

    </StyledContainer>
));


export default Component;

