import { Config, CognitoIdentityCredentials } from 'aws-sdk';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
const {config} = window ;

Config.region = config.region ;
Config.credentials = new CognitoIdentityCredentials({
  IdentityPoolId: config.identityPoolId
});
let userPool = new CognitoUserPool({
  UserPoolId: config.userPoolId,
  ClientId: config.userPoolClientId
});

export const getUserPool = () => {
  return userPool;
}

export const getUser = () => {
  return userPool.getCurrentUser();
}

let currentUser ;

export const setCurrentUserSession = user => {
  const isAuthed = !!user.signInUserSession;
  // console.log(`isAuthed = ${isAuthed}`);
  currentUser = user;
}

export const getCurrentUserSession = () => {
  return currentUser;
}
