import React from 'react';
import { Alert } from 'reactstrap';
import { toastr } from 'react-redux-toastr';

// generic error handling to run at the top of event loops (i.e. sagas)
export const sagaErrorGetMessage = (interaction, err) => {
  if (!err) {
    return 'error not set';
  }
  let message;
  if (err.data) { // exception came from server
      message = err.data.error;
  } else {
      message = err.message;
  }
  return `Error : ${message}`;
};

export const sagaEntry = (interaction, innerGeneratorFunc, beNoisy=true) =>
  function*(...args) {
    try {
      yield* innerGeneratorFunc(...args);
    } catch (err) {
        if (err.code==="NotAuthorizedException") {

        }
        console.log("error");
        console.log(err);
        if (beNoisy) {
          const message = sagaErrorGetMessage(interaction, err);
          toastr.warning('Warning', false, {
              timeOut: 10000,
              showCloseButton: true,
              component: <Alert {...{color: 'warning'}}>{message}</Alert>,
          });
        }
    }
  };
