import PropTypes from 'prop-types';
import React from 'react';
import {connect} from "react-redux";
import {compose, setPropTypes,withProps,withState} from 'recompose';
import {Container, Row, Col, Form, Button, Alert} from 'reactstrap';
import {NavLink, withRouter} from "react-router-dom";
import {withFormik} from "formik";
import * as EmailValidator from "email-validator";
import {StyledForm,StyledContainer} from '../../style';
import { cognitoLogin } from "../../common/state/ducks/awsCognito/actions";
import {InputType, SchemaType} from "../../common/utils/constants";
import FormField from '../../components/terminal/FormField';

const formSchema = {
    $id: 'LoginForm',
    description: 'Log in to Healthpoint FHIR API',
    type: SchemaType.object,
    properties: {
        username: {
            label: 'E-mail',
            description: 'E-mail',
            type: 'email',
            inputType: InputType.EMAIL,
        },
        password: {
            label: 'Password',
            description: 'Password',
            type: 'password',
            inputType: InputType.PASSWORD,
        }
    },
};

const LoginPage = compose(
    connect(state => ({
        apiGw: state.apiGw,
        accountDetails: state.cognito.accountDetails,
    }),{
        cognitoLogin
    }),
    withRouter,
    withState('errorCode','setErrorCode',undefined),
    withFormik({
        mapPropsToValues: () => {
            const values = {username:"",password:""};
            return values;
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: values => {
            const errors = {} ;
            if (!values.username || !EmailValidator.validate( values.username) ) {
                errors.username = "Please enter a valid e-mail address";
            }
            return errors;
        },
        handleSubmit: async (values, formikBag) => {
            const {error}  = await new Promise((resolve,reject) => {
                formikBag.props.cognitoLogin({...values,resolve,reject});
            });
            console.dir({ error });
            if (error) {
                formikBag.props.setErrorCode(error.code);
            } else {
                formikBag.props.history.push("/dashboard");
            }
        },
        displayName: formSchema.$id,
    }),
    setPropTypes({
        values: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    }),
    withProps(props => ({
        disabled: !props.isValid || !props.dirty
    }))
)(({ handleSubmit, disabled, errorCode, ...props }) => {
    return (
        <StyledContainer>
                <h1>Login</h1>
                <hr/>
                <p>
                    Log in to view your API key, accessible APIs and usage or else
                    {' '}<NavLink to="/register">register</NavLink> if you have not already.
                </p>
                {errorCode==="UserNotConfirmedException" &&
                    <Alert color="warning">
                        <h4 className="alert-heading text-center">
                            User not confirmed
                        </h4>
                        <p className="text-center">
                            Please go to your
                            {' '}<a target="_blank" rel="noopener noreferrer" href={`mailto:${props.values.email}`}>email</a>{' '}
                            and click the verification link.
                        </p>
                    </Alert>
                }
                {errorCode === "UserNotFoundException" &&
                <Alert color="warning">
                    <h4 className="alert-heading text-center">
                        User not found
                    </h4>
                    <p className="text-center">
                        Please <NavLink to="/register">register</NavLink>  before logging in.
                    </p>
                </Alert>
                }
                {errorCode === "NotAuthorizedException" &&
                <Alert color="warning">
                    <h4 className="alert-heading text-center">
                        Invalid password
                    </h4>
                    <p className="text-center">
                        If you have forgotten your password you may reset it <NavLink to="/password-reset">here</NavLink>.
                    </p>
                </Alert>
                }
                {errorCode === "PasswordResetRequiredException" &&
                <Alert color="warning">
                    <h4 className="alert-heading text-center">
                        A password reset is required
                    </h4>
                    <p className="text-center">
                        You can do that <NavLink to="/password-reset">here</NavLink>.
                    </p>
                </Alert>
                }
                <Container className="p-0">
                    <Row key={1}>
                        <Col>
                            <StyledForm className="p-3">
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md="12">
                                            <FormField
                                                {...props}
                                                baseSchema={formSchema}
                                                path={['username']}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormField
                                                {...props}
                                                baseSchema={formSchema}
                                                path={['password']}
                                            />
                                        </Col>
                                    </Row>
                                    <Button disabled={disabled} type={'submit'}>Login</Button>
                                </Form>
                            </StyledForm>
                        </Col>
                    </Row>
                </Container>
                <br/>
                <p>
                    If you have forgotten your password click <NavLink to="/password-reset">here</NavLink>.
                </p>
            </StyledContainer>
    );
});

export default LoginPage;
