import React from 'react';
import moment from "moment";
import { FormGroup, Label } from 'reactstrap';
import { compose, setPropTypes, withHandlers, withProps } from 'recompose';
import PropTypes from 'prop-types';
import InputComponent from './InputComponent';
import { InputType } from '../../common/utils/constants';
import { getSchemaFromPath } from '../../common/utils';

export const getValueOnChange = (schema, e) => {
  switch (schema.inputType) {
    case InputType.DATE: {
      const date = moment(e.target.value).format("yyyy-MM-dd").toDate();
      // console.dir({date});
      return date;
    }
    case InputType.EMAIL:
    case InputType.TEL:
    case InputType.TEXT:
    case InputType.TEXTAREA:
    case InputType.NUMBER:
    case InputType.SELECT:
    case InputType.CHECKBOXSELECT:
    case InputType.PASSWORD:
      return e.target.value;
    case InputType.CHECKBOX:
      return !!e.target.checked;
    case InputType.OPTION:
    case InputType.OPTIONS:
      return e;
    case InputType.RADIOSELECT:
      return e;
    default:
      throw new Error(`Invalid schema.inputType: ${schema.inputType}`);
  }
};

const InputFeedback = ({ error }) =>
    error ? <div className="input-feedback">{error}</div> : null;

const FormField = compose(
  withProps(({ baseSchema, path, isHideLabel }) => {
    const schema = getSchemaFromPath(baseSchema, path);
    const fieldName = path.join('.');
    const label = isHideLabel ? false : ( schema.label || schema.title);
    return {
      schema,
      fieldName,
      label,
    };
  }),
  withHandlers({
    onChange: props => e => {
      const value = getValueOnChange(props.schema, e);
      props.handleChange(e);
      props.setFieldValue(props.fieldName, value);
      props.setFieldTouched(props.fieldName, true);
      if (props.schemaFieldChanged) {
        props.schemaFieldChanged(props.fieldName, value);
      }
    },
    onBlur: props => e => {
      // console.dir({e});
      props.handleBlur(e);
    },
  }),
  setPropTypes({
    path: PropTypes.array.isRequired,
    baseSchema: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
  })
)(
  ({
    schema,
    fieldName,
    label,
    placeholder,
    disabled,
    onChange,
    onBlur,
    setFieldValue,
    setFieldTouched,
    ...props
  }) => {
    const isCheckbox = schema.inputType === InputType.CHECKBOX;
    if (!schema.inputType) {
      throw new Error(`schema for ${fieldName} must have an input type`);
    }
    const inputComponent = (
      <InputComponent
        {...{
          ...props,
          type: schema.inputType,
          id: fieldName,
          value: props.values[fieldName],
          onChange,
          onBlur,
          disabled,
          placeholder,
          setFieldValue,
          setFieldTouched,
          options: props.options ? props.options : schema.options
        }}
      />
    );
    let labelledInput;
    switch (schema.inputType) {
      case InputType.CHECKBOX:
        labelledInput = (
          <Label check>
            {inputComponent}
            <span>{label}</span>
          </Label>
        );
        break;
      case InputType.RADIOSELECT:
        labelledInput = inputComponent;
        break;
      default:
        labelledInput = (
          <div>
            {label && <Label for={fieldName}>{label}</Label>}
            {inputComponent}
          </div>
        );
    }
    return (
      <FormGroup
        {...{
          color:
            props.errors[fieldName] && props.touched[fieldName] && 'danger',
          ...(isCheckbox && { check: true }),
        }}
      >
        {labelledInput}
        <InputFeedback error={props.errors[fieldName]} />
      </FormGroup>
    );
  }
);

export default FormField;
