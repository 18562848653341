import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import rootSaga from './sagas';
import * as reducers from './ducks';
import {history} from '../../common/utils';

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger({
  level: 'info',
  collapsed: true,
});

// console.dir({reducers});

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  toastr: toastrReducer,
  ...reducers,
});

export const configureStore = initialState => {
  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(
      applyMiddleware(
        sagaMiddleware,
        loggerMiddleware,
        routerMiddleware(history) // for dispatching history actions
      ),
      window.devToolsExtension ? window.devToolsExtension() : f => f
    )
  );
  sagaMiddleware.run(rootSaga);

  // hot reloading: https://medium.com/@dan_abramov/hot-reloading-in-react-1140438583bf
  if (module.hot) {
    module.hot.accept('./ducks', () => {
      const nextReducer = require('./ducks').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }
  return store;
};
