import React from "react";
import styled from "styled-components";
import {Modal, ModalBody, ModalHeader} from "reactstrap";

export const StyledForm = ({children}) => (
    <div className="hpapi-form p-3">
        {children}
    </div>
);

export const StyledContainer = ({children}) => (
    <div className="py-3 container">
        {children}
    </div>
);

export const DivForEnv = styled.div`  
  border-color: ${props => props.theme.borderColor};
  background-color: ${props => props.theme.backgroundColor};
`;

export const TrForEnv = styled.tr`  
  border-color: ${props => props.theme.borderColor};
  background-color: ${props => props.theme.backgroundColor};
`;

export const StyledModal = styled(Modal)`
  position: relative;
  top: 50%;
  transform: translateY(-50%) !important;
`;

export const StyledModalHeader = styled(ModalHeader)`
  border-bottom: none;
  h4 {
    font-size: 24px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin: 15px 0px;
  }
  button {
    position: relative;
    top: -20px;
  }
`;

export const StyledModalBody = styled(ModalBody)`
  min-height: 100px;
  margin: 0 20px 20px 20px;
`;
