import { createAction } from 'redux-actions';
import { AwsApiInteractions } from './types';

export const apiGwInit  = createAction(
    `${AwsApiInteractions.API_GW_INIT}_REQ`
);
export const apiGwSignin = createAction(
    `${AwsApiInteractions.SIGN_IN}_REQ`
);
export const apiAcceptTncs = createAction(
    `${AwsApiInteractions.ACCEPT_TNCS}_REQ`
);
export const apiGwApiUsageGet  = createAction(
    `${AwsApiInteractions.API_USAGE_GET}_REQ`
);
export const apiGwUsersGet  = createAction(
    `${AwsApiInteractions.API_USERS_GET}_REQ`
);
