import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'

import { getUserPool, setCurrentUserSession } from './config'

export default function(username, password) {
  const authenticationData = {
    Username: username,
    Password: password
  }
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: username,
    Pool: getUserPool()
  }

  const cognitoUser = new CognitoUser(userData)
  // console.dir({ authenticationData, userData });

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(userSession) {
        setCurrentUserSession(cognitoUser);
        resolve({userSession});
      },
      onFailure: function(err) {
        const {code} = err;
        //console.dir({ err, code });
        resolve( { error: {code} } );
      },
      newPasswordRequired: function(userAttributes, requiredAttributes) {
        //console.dir({ userAttributes, requiredAttributes });
        reject({
          code: 'PasswordResetRequiredException',
          message: 'New Password Required',
          newPasswordRequired: true
        })
      }
    })
  })
}
