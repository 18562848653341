import React from 'react';
import { compose, setPropTypes, withHandlers } from 'recompose';
import { FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { mapIndexed } from '../../common/utils';

const SelectRadio = compose(
  setPropTypes({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
  }),
  withHandlers({
    onChangeRadio: props => idx => () => {
      const value = props.options[idx].value;
      props.onChange(value);
    },
  })
)(({ name, value, onChangeRadio, options }) => (
  <div>
    {mapIndexed(
      (opt, idx) => (
        <FormGroup check key={opt.label}>
          <Label check>
            <Input
              type="radio"
              name={name}
              value={opt.value === value}
              checked={opt.value === value}
              onChange={onChangeRadio(idx)}
            />
            {` ${opt.label}`}
          </Label>
        </FormGroup>
      ),
      options
    )}
  </div>
));
export default SelectRadio;
