import React from 'react';
import { compose, setPropTypes } from 'recompose';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const SelectCheckbox = compose(
  setPropTypes({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
  })
)(({ id, name, value, onChange, onBlur, disabled, options }) => (
  <select
    {...{
      id,
      name,
      value: value || '',
      onChange,
      onBlur,
      disabled,
    }}
  >
    {R.map(
      opt => (
        <option key={opt.label} value={opt.value}>
          {opt.label}
        </option>
      ),
      [{ label: 'Choose...' }].concat(options)
    )}
  </select>
));

export default SelectCheckbox;
