import * as R from 'ramda';
import { AwsCognitoInteractions } from './types';

const INIT_STATE = {
  userSession:{},
  accountDetails:{},
  isAuthenticated:false,
  isLoading: false,
  isAdmin: false
};

export default function tab(state = INIT_STATE, action) {
  switch (action.type) {
    case `${AwsCognitoInteractions.LOGOUT}_RES`:{
      return {
        ...INIT_STATE,
        userSession:null,
      };
    }
    case `${AwsCognitoInteractions.LOGIN}_RES`:
    case `${AwsCognitoInteractions.APP_START}_RES`: {
      const {userSession,accountDetails,user} = action.payload ;
      const isAuthenticated = !!userSession ;
      const email = accountDetails && accountDetails.email;
      const isAdminUser = email && ((email.search(/healthpointapi\.co\.nz$/i)>=0) || (email.search(/portela\.co\.nz$/i)>=0) || email=="gavin.milbank@gmail.com");
      return {
        ...state,
        userSession,
        user,
        accountDetails,
        isAuthenticated,
        isAdminUser,
        isInited:true
      };
    }
    default:
      return state;
  }
}
